import {all, call, put, takeEvery} from 'redux-saga/effects';
import {query, getDocs, collection} from 'firebase/firestore';
import {fbFirestore} from '../../../helpers/firebaseClient';
import actions from './actions';


export function* getNotifications() {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'notifications'))));
        yield put({
            type: actions.GET_NOTIFICATIONS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        yield put({ type: actions.GET_NOTIFICATIONS_FAILURE });
        return error;
    }
}

export default function* notificationsSaga() {
    yield all([
        takeEvery(actions.GET_NOTIFICATIONS_START, getNotifications),
    ]);
}
