import actions from './actions';

const initState = {
    loader: false,
    notifications: []
};

export default function notificationsReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_NOTIFICATIONS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                loader: false,
                notifications: action.payload,
            };
        }
        case actions.GET_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
