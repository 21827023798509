import React, {useEffect, useState} from 'react'
import {Breadcrumbs, Grid, Link, Typography} from '@mui/material'
// import {Link} from 'react-router';
import {makeStyles} from '@mui/styles';
import { Toaster } from 'react-hot-toast';
import {dashboardCard} from '../../helpers/constant';
import DashLayout from '../Shared/DashLayout';
import { DisclaimerFooter } from '../Shared/DisclaimerFooter';

const Dashboard = () => {
    const classes = customStyles();
    return(
        <>
            <Toaster />
            <DashLayout component={
            <div>
                <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                    <Link className={classes.textDecoration}> Dashboard </Link>
                </Breadcrumbs>
                <div style={{padding: 10}}>
                    <Typography variant='h6' className={classes.title}>
                        <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>PRODUCTS</span>
                    </Typography> 
                    <Grid container className={classes.flexCenter}>
                        {dashboardCard.map(dashboard => (
                            <Grid item lg={3} md={4} sm={12} style={{padding: 10}}>
                                <div style={{backgroundColor: `${dashboard.bgColor}`, padding: 10, borderRadius: 20}}>
                                    <Typography variant='h6' style={{fontSize:'24px', margin: '0.5em', color: '#00234B', fontWeight: 600}}>{dashboard.title}</Typography>
                                    <Typography variant='h6' style={{fontSize:'16px', margin: '0.5em', color: '#00234B', fontWeight: 500}}>{dashboard.value.toLocaleString()}</Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <DisclaimerFooter />
            </div>
            } />
        </>
    )
}

export default Dashboard;

const customStyles = makeStyles({
    title:{
        flexGrow: 1,
        color: '#FF5733',
        fontWeight: 600,
    },
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',

        '&:hover':{
            color: '#182230',
        }
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: '40px',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
