import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Avatar, Breadcrumbs, Button, Grid, Grid2, Link, Paper, Tabs, Tab, Typography, Divider} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import {makeStyles} from '@mui/styles';
import { Toaster } from 'react-hot-toast';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import DashLayout from '../../Shared/DashLayout';


const FirstLine = () => {
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        // dispatch({type: actions.GET_PROPERTIES_START});
    }
    const loadProperty = (value) => {
        // dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const classes = customStyles();
    return(
        <>
            <Toaster />
            <DashLayout component={
                <div>
                    <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                        <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                        <Link className={classes.textDecoration}> First-Line </Link>
                    </Breadcrumbs>
                    <Typography variant='h5' style={{color: '#9900CC', padding: 10, fontWeight: 600}}>First-Line</Typography>
                    <div style={{margin: 20, marginTop: 10}}>
                        <Typography variant='h6' style={{paddingLeft: 10, fontWeight: 600}}>Life Insurance to Protect</Typography>
                        <Typography variant='subtitle' style={{fontSize: '14px', color: '#7F90A2', paddingLeft: 10, fontWeight: 600}}>Our Analysis on Life Insurance Plan</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6} key={1}>
                                <Paper style={{margin: '0.5rem', padding: '0.5rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                    <Grid container style={{margin: '10px'}}>
                                        <Grid item xs={3} md={2} lg={2} style={{padding: 10}}>
                                            <Avatar sx={{ bgcolor: '#FFEDD7', padding:1 }} variant="rounded">
                                                <MedicalInformationOutlinedIcon fontSize='large' sx={{color: '#FF9900'}} />
                                            </Avatar> 
                                        </Grid>
                                        <Grid item xs={9} md={10} lg={10} style={{padding: 10}}>
                                            <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '11px'}}>
                                                Type: Life Insurance
                                            </Typography>
                                            <Typography variant='h5' className={classes.padding8} style={{fontSize:'18px', fontWeight: 600}}>
                                                Term Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{padding: 10}}>
                                            <Typography variant='subtitle' align='center' className={classes.padding8}>
                                                The most risk-free and the simplest way to financially secure the future of your loved ones in the event of your untimely demise. And this is possible at low premiums for a high sum assured.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                            <Button variant='outlined' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}}>Learn More</Button>
                                        </Grid> 
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                            <Button variant='contained' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} href='https://wa.me/+919677992240?text=I%20am%20interested%20in%20your%20Term%20Insurance%20Plan'>Apply</Button>
                                        </Grid>                                          
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} key={1}>
                                <Paper style={{margin: '0.5rem', padding: '0.5rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                    <Grid container style={{margin: '10px'}}>
                                        <Grid item xs={3} md={2} lg={2} style={{padding: 10}}>
                                            <Avatar sx={{ bgcolor: '#E0F2EE', padding:1 }} variant="rounded">
                                                <MedicalInformationOutlinedIcon fontSize='large' color='success' />
                                            </Avatar> 
                                        </Grid>
                                        <Grid item xs={9} md={10} lg={10} style={{padding: 10}}>
                                            <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '11px'}}>
                                                Type: Life Insurance
                                            </Typography>
                                            <Typography variant='h5' className={classes.padding8} style={{fontSize:'18px', fontWeight: 600}}>
                                                ULIPs Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{padding: 10}}>
                                            <Typography variant='subtitle' align='center' className={classes.padding8}>
                                                ULIP offers dual benefits in the form of life insurance cover and wealth creation through investment, all under a single plan of ULIP.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                            <Button variant='outlined' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}}>Learn More</Button>
                                        </Grid> 
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                            <Button variant='contained' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} href='https://wa.me/+919677992240?text=I%20am%20interested%20in%20your%ULIPs%20Insurance%20Plan'>Apply</Button>
                                        </Grid>                                          
                                    </Grid>
                                </Paper>
                            </Grid>                  
                        </Grid>
                    </div>
                    <div style={{margin: 20, marginTop: 10}}>
                        <Typography variant='h6' style={{paddingLeft: 10, fontWeight: 600}}>Health Insurance to Protect</Typography>
                        <Typography variant='subtitle' style={{fontSize: '14px', color: '#7F90A2', paddingLeft: 10, fontWeight: 600}}>Our Analysis on Health Insurance Plan</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6} key={1}>
                                <Paper style={{margin: '0.5rem', padding: '0.5rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                    <Grid container style={{margin: '10px'}}>
                                        <Grid item xs={3} md={2} lg={2} style={{padding: 10}}>
                                            <Avatar sx={{ bgcolor: '#E6ECFF', padding:1 }} variant="rounded">
                                                <MedicalInformationOutlinedIcon fontSize='large' sx={{color: '#0033CC'}} />
                                            </Avatar> 
                                        </Grid>
                                        <Grid item xs={9} md={10} lg={10} style={{padding: 10}}>
                                            <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '11px'}}>
                                                Type: Health Insurance
                                            </Typography>
                                            <Typography variant='h5' className={classes.padding8} style={{fontSize:'18px', fontWeight: 600}}>
                                                Individual Health Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{padding: 10}}>
                                            <Typography variant='subtitle' align='center' className={classes.padding8}>
                                                Protects you against the financial implications of a wide variety of health-related expenses, ranging from those caused by minor illnesses and injuries to critical diseases.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                            <Button variant='outlined' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}}>Learn More</Button>
                                        </Grid> 
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                            <Button variant='contained' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} href='https://wa.me/+919677992240?text=I%20am%20interested%20in%20your%20Individual%20Health%20Insurance%20Plan'>Apply</Button>
                                        </Grid>                                          
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} key={1}>
                                <Paper style={{margin: '0.5rem', padding: '0.5rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                    <Grid container style={{margin: '10px'}}>
                                        <Grid item xs={3} md={2} lg={2} style={{padding: 10}}>
                                            <Avatar sx={{ bgcolor: '#FFE6F9', padding:1 }} variant="rounded">
                                                <MedicalInformationOutlinedIcon fontSize='large' sx={{color: '#CC0099'}} />
                                            </Avatar> 
                                        </Grid>
                                        <Grid item xs={9} md={10} lg={10} style={{padding: 10}}>
                                            <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '11px'}}>
                                                Type: Health Insurance
                                            </Typography>
                                            <Typography variant='h5' className={classes.padding8} style={{fontSize:'18px', fontWeight: 600}}>
                                                Family Health Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{padding: 10}}>
                                            <Typography variant='subtitle' align='center' className={classes.padding8}>
                                                Protects you against the financial implications of a wide variety of health-related expenses, ranging from those caused by minor illnesses and injuries to critical diseases.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                            <Button variant='outlined' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}}>Learn More</Button>
                                        </Grid> 
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                            <Button variant='contained' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} href='https://wa.me/+919677992240?text=I%20am%20interested%20in%20your%20Family%20Health%20Insurance%20Plan'>Apply</Button>
                                        </Grid>                                          
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} key={1}>
                                <Paper style={{margin: '0.5rem', padding: '0.5rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                    <Grid container style={{margin: '10px'}}>
                                        <Grid item xs={3} md={2} lg={2} style={{padding: 10}}>
                                            <Avatar sx={{ bgcolor: '#FFFFE6', padding:1 }} variant="rounded">
                                                <MedicalInformationOutlinedIcon fontSize='large' sx={{color: '#E6E600'}} />
                                            </Avatar> 
                                        </Grid>
                                        <Grid item xs={9} md={10} lg={10} style={{padding: 10}}>
                                            <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '11px'}}>
                                                Type: Health Insurance
                                            </Typography>
                                            <Typography variant='h5' className={classes.padding8} style={{fontSize:'18px', fontWeight: 600}}>
                                                ULIPs Health Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{padding: 10}}>
                                            <Typography variant='subtitle' align='center' className={classes.padding8}>
                                                Protects you against the financial implications of a wide variety of health-related expenses, ranging from those caused by minor illnesses and injuries to critical diseases.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                            <Button variant='outlined' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}}>Learn More</Button>
                                        </Grid> 
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                            <Button variant='contained' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} href='https://wa.me/+919677992240?text=I%20am%20interested%20in%20your%20ULIPs%20Health%20Insurance%20Plan'>Apply</Button>
                                        </Grid>                                          
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} key={1}>
                                <Paper style={{margin: '0.5rem', padding: '0.5rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                    <Grid container style={{margin: '10px'}}>
                                        <Grid item xs={3} md={2} lg={2} style={{padding: 10}}>
                                            <Avatar sx={{ bgcolor: '#FAEAF0', padding:1 }} variant="rounded">
                                                <MedicalInformationOutlinedIcon fontSize='large' sx={{color: '#FF5050'}} />
                                            </Avatar> 
                                        </Grid>
                                        <Grid item xs={9} md={10} lg={10} style={{padding: 10}}>
                                            <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '11px'}}>
                                                Type: Health Insurance
                                            </Typography>
                                            <Typography variant='h5' className={classes.padding8} style={{fontSize:'18px', fontWeight: 600}}>
                                                Group Health Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{padding: 10}}>
                                            <Typography variant='subtitle' align='center' className={classes.padding8}>
                                                The most risk-free and the simplest way to financially secure the future of your loved ones in the event of your untimely demise. And this is possible at low premiums for a high sum assured.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                            <Button variant='outlined' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}}>Learn More</Button>
                                        </Grid> 
                                        <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                            <Button variant='contained' style={{paddingTop: 5, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} href='https://wa.me/+919677992240?text=I%20am%20interested%20in%20your%20Group%20Health%20Insurance%20Plan'>Apply</Button>
                                        </Grid>                                          
                                    </Grid>
                                </Paper>
                            </Grid>                   
                        </Grid>
                    </div>
                </div>
            } />
        </>
    );
}

export default FirstLine;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: 'none',
        color: '#7F9082',
    
        '&:hover':{
            color: '#182230',
        }
    },
})
