import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD_3jl8uorvTx2qU-dsPCFXL9QLwG2Fkks",
  authDomain: "ace-matrix.firebaseapp.com",
  projectId: "ace-matrix",
  storageBucket: "ace-matrix.firebasestorage.app",
  messagingSenderId: "393100214337",
  appId: "1:393100214337:web:6bf587fe02ff995a9386c0",
  measurementId: "G-9EG9CNZ7W2"
};

// Initialize Firebase
export const firebaseInit = initializeApp(firebaseConfig);
export const fbFirestore = getFirestore();
export const fbStorage = getStorage(firebaseInit);
