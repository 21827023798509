import {Link} from 'react-router';
import {Box, Grid, Typography} from '@mui/material';
import {footerLeftMenus, footerMidMenus} from '../../helpers/constant';


const Footer = () => {
    return(
        <>
            <Box component='footer' style={{padding: 10, margin: 15, borderRadius: 20, border: '1px solid rgb(234, 231, 239)'}}>
                <div>
                <Grid container>
                    <Grid item xs={12} md={4.5}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography variant='h5' style={{fontWeight: 600, color: '#1A56DB', marginBottom: 10}}> ACE MATRIX </Typography>
                            <div>
                                <Typography style={{fontSize: '16px', padding: 0, color: '#182230'}}> We want India's Future Pillars to travel in passionate and organised way to empower their standard of living and adopt to unpredictable economic changes happening in India. Not only abroad employement will make you rich but also the plan that every millionaires and billionaires followed to get successful with the opportunities in India. Our Membership will be your guide to reach such goals.</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '16px', margin: '0.5em', color: '#607285', fontWeight: 500}}>{footerLeftMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {footerLeftMenus.sub.map(menus => (
                                    <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#182230', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '16px', margin: '0.5em', color: '#607285', fontWeight: 500}}>{footerMidMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {footerMidMenus.sub.map(menus => (
                                    <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#182230', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '16px', margin: '0.5em', color: '#607285', fontWeight: 500}}>Contact</Typography>
                            <div style={{paddingLeft: 10}}>
                                {/* <Typography variant='h5' style={{fontSize: '18px', padding: 5, fontWeight: 500}}>Ace Matrix</Typography>
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5}}>Paramathi Velur</Typography>
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5}}>Namakkal District - 637 207</Typography> */}
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5}}>Email: mohankandhaiya9@gmail.com</Typography>
                                <Typography variant='h5' style={{fontSize: '16px', padding: 5, fontWeight: 500}}>Cell: 96779 92240</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{padding: 10, margin: 10}}>
                <Typography
                    variant='h6'
                    align='center'
                    style={{fontWeight: 400, color: '#182230', fontSize: '16px'}}
                >
                    We are financial service company | Ace Matrix | acematrix.in | Privacy Policy | Terms & Conditions
                </Typography>
                <Typography
                    variant='subtitle1'
                    align='center'
                    style={{color: '#182230', fontSize: '16px'}}
                >
                    {'Copyright © '}
                    <Link href='https://tech.acematrix.in/' style={{textDecoration: 'none', color: '#1A56DB', fontSize: '15px', fontWeight: 600}}>
                        Kandhaiya Tech
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </div>
            </Box>
            <div style={{padding: 2}}></div>
        </>
    );
}

export default Footer;
