import actions from './actions';

const initState = {
    isLoggedIn: false,
    loggedInUser: '',
    loggedInUserProfileId: '',
    loggedInUserProfileDetails: [],
    userDetails:  [{
        name: 'Ace Matrix User',
        email: 'amuser@gmail.com',
        contact: '',
        gender: '',
        interestedIn: '',
        experience: '',
        qualification: '',
        gender: '',
        age: '',
        city: '',
        state: '',
        country: 'India',
        pincode: ''
    }],
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.USER_REGISTRATION_START: {
            return {
                ...state,
                registerLoader: true,
            };
        }
        case actions.USER_REGISTRATION_SUCCESS: {
            return {
                ...state,
                register: action.payload,
                registerLoader: false,
            };
        }
        case actions.USER_REGISTRATION_FAILURE: {
            return {
                ...state,
                registerLoader: false,
            };
        }
        case actions.USER_REGISTRATION_GOOGLE_START: {
            return {
                ...state,
                googleRegisterLoader: true,
            };
        }
        case actions.USER_REGISTRATION_GOOGLE_SUCCESS: {
            return {
                ...state,
                googleRegister: action.payload,
                googleRegisterLoader: false,
            };
        }
        case actions.USER_REGISTRATION_GOOGLE_FAILURE: {
            return {
                ...state,
                googleRegisterLoader: false,
            };
        }
        case actions.LOGIN_START: {
            return {
                ...state,
                loginLoader: true,
            };
        }
        case actions.LOGIN_SUCCESS: {
            return {
                ...state,
                loginLoader: false,
                isLoggedIn: true,
                loggedInUser: action.payload.uid,
                loggedInUserProfileId: action.authDetails.userId,
                loggedInUserProfileDetails: action.authDetails[0],
            };
        }
        case actions.LOGIN_FAILURE: {
            return {
                ...state,
                loginLoader: false,
            };
        }
        case actions.GOOGLE_LOGIN_START: {
            return {
                ...state,
                loginLoader: true,
            };
        }
        case actions.GOOGLE_LOGIN_SUCCESS: {
            return {
                ...state,
                loginLoader: false,
                isLoggedIn: true,
                loggedInUser: action.payload.uid,
            };
        }
        case actions.GOOGLE_LOGIN_FAILURE: {
            return {
                ...state,
                loginLoader: false,
            };
        }
        case actions.LOGOUT_START: {
            return {
                ...state,
                logoutLoader: true,
            };
        }
        case actions.LOGOUT_SUCCESS: {
            return {
                ...state,
                isLoggedOut: true,
                logoutLoader: false,
            };
        }
        case actions.LOGOUT_FAILURE: {
            return {
                ...state,
                logoutLoader: false,
            };
        }
        case actions.GET_USER_DETAILS_START: {
            return {
                ...state,
                userDetailsLoader: true,
            };
        }
        case actions.GET_USER_DETAILS_SUCCESS: {
            return {
                ...state,
                userDetails: action.payload,
                userDetailsLoader: false,
            };
        }
        case actions.GET_USER_DETAILS_FAILURE: {
            return {
                ...state,
                userDetailsLoader: false,
            };
        }
        default: return state;
    }
}
