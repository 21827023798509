import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Link, Tabs, Tab, Typography} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import {makeStyles} from '@mui/styles';
import { Toaster } from 'react-hot-toast';
import DashLayout from '../Shared/DashLayout';


const UserSubscription = () => {
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        // dispatch({type: actions.GET_PROPERTIES_START});
    }
    const loadProperty = (value) => {
        // dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const classes = customStyles();
    return(
        <>
            <Toaster />
            <DashLayout component={
                <div>
                    <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                        <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                        <Link href={'/user/account'} className={classes.textDecoration} > Account </Link>
                        <Link className={classes.textDecoration}> Subscription </Link>
                    </Breadcrumbs>
                    <Typography variant="h5" style={{color: "#CC9900", padding: 10, fontWeight: 600}}>Subscription</Typography>
                    <div style={{margin: 20, marginTop: 10}}>
                        
                    </div>
                </div>
            } />
        </>
    );
}

export default UserSubscription;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',
    
        '&:hover':{
            color: '#182230',
        }
    },
})
