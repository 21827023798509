export const roles = [
    {
        name: 'Admin',
        slug: 'admin',
    },
    {
        name: 'Partner',
        slug: 'partner',
    },
];

export const menus = [
    {
        link: "/",
        name: "Home",
        variant: "default",
        button: false,
    },
    {
        link: "/discover",
        name: "Discover",
        variant: "default",
        button: false,
    },
    {
        link: "/products",
        name: "Products",
        variant: "default",
        button: false,
    },
    {
        link: "/subscribe",
        name: "Subscribe",
        variant: "default",
        button: false,
    },
    {
        link: "/about",
        name: "About",
        variant: "default",
        button: false,
    },
    {
        link: "/partner-program",
        name: "Become a Partner",
        variant: "default",
        button: false,
    },
    {
        link: "/buy-me-a-coffee",
        name: "Buy Me a Coffee",
        variant: "default",
        button: true,
    },
    {
        link: "/user/dashboard",
        name: "Login",
        variant: "default",
        button: true,
    },
];

export const footerLeftMenus = {
    menu: 'Resources',
    sub: [
        {
            menu: 'Discover',
            link: '/discover',
        },
        {
            menu: 'Products',
            link: '/products',
        },
        {
            menu: 'Subscribe',
            link: '/subscribe',
        },
    ],
}

export const footerMidMenus = {
    menu: 'Company',
    sub: [
        {
            menu: 'About',
            link: '/about',
        },
        {
            menu: 'User Login',
            link: '/user/login',
        },
        {
            menu: 'User Registration',
            link: '/user/register',
        },
    ],
}

export const products = [
    {
        title: 'Ace Clutch',
        slug: 'ace-clutch',
        shortDesc: 'Achieve your goals with our Clutch Tool',
        link: '/user/clutch',
        btnTitle: 'Go to Clutch',
        bgColor: '#E0F2EE'
    },
    {
        title: 'Ace Pursuit',
        slug: 'ace-pursuit',
        shortDesc: 'Gain knowledge with our Courses',
        link: '/user/pursuit',
        btnTitle: 'Start your Courses',
        bgColor: '#FAEAF0'
    },
    // {
    //     title: 'Ace Radar',
    //     slug: 'ace-radar',
    //     shortDesc: 'Find stock that meet your investment preferences with Radar',
    //     link: '/user/radar',
    //     btnTitle: 'Go to Radar'
    // },
    {
        title: 'Ace First-Line',
        slug: 'ace-firstline',
        shortDesc: 'Standard plan to protect your beloved',
        link: '/user/firstline',
        btnTitle: 'Go to First-Line',
        bgColor: '#FFEDD7'
    },
    {
        title: 'Ace Fortune',
        slug: 'ace-fortune',
        shortDesc: 'Our handpicked investment choices',
        link: '/user/firstline',
        btnTitle: 'Go to Fortune',
        bgColor: '#CFCFFC'
    },
];

export const dashboardCard = [
    {
        title: 'Clutch',
        link: '',
        value: 'A Financial Tool to achieve your goals with Stocks & Mutual Funds',
        bgColor: '#E0F2EE'
    },
    {
        title: 'Pursuit',
        link: '',
        value: 'Online Learning Platform to gain knowledge about all Financial Needs',
        bgColor: '#FAEAF0'
    },
    {
        title: 'Fortune',
        link: '',
        value: 'Gain access to best low risk investments like FDs, ETFs, MFs',
        bgColor: '#CFCFFC'
    },
    {
        title: 'First-Line',
        link: '',
        value: 'One-stop solution for all types of Insurance and Loans',
        bgColor: '#FFEDD7'
    },
    {
        title: 'Clan',
        link: '',
        value: 'Joining our clan brings you a unique business experience',
        bgColor: '#CCFFFF'
    },
    {
        title: 'Radar',
        link: '',
        value: 'Fundamental Analysis Platform for Stocks, Cryptos, Forex & Indices',
        bgColor: '#FFFFCC'
    },
    // {
    //     title: 'One',
    //     value: 'A Share Trading Platform with best broking experience',
    //     bgColor: '#CCFFCC'
    // },
]

export const faq = [
    {
        query: 'What is Ace Matrix?',
        answer: 'Ace Matrix is for people who want to empower their knowledge about finances & investment opportunities to build strategies and achieve financial independence',
        bgColor: '#F3F3F6'
    },
    {
        query: 'How to use Ace Matrix?',
        answer: 'Ace Matrix is one of Indias largest finance communities. We offer more than just a course. As a lifetime membership, you gain access to offline events across India, a lively social media platform connecting finance enthusiasts, and the latest financial news. With a focus on implementing knowledge through our financial tools, our community is comprised of experienced members who excel in their fields, earning over 10 lakh per year.',
        bgColor: '#F3F3F6'
    },
    {
        query: 'Who can get benefited from our subscription?',
        answer: 'Young Professionals, Business Owners, Entrepreneurs, Employees planning for Early Retirement, People who needs financial planning, Swing Traders, Investors, Market Analysts',
        bgColor: '#F3F3F6'
    },
    {
        query: 'How can I register?',
        answer: 'Ace Matrix is a members only community of finance enthusiasts and people who want to take control of their finances and achieve financial independence. Achieving the top 1% is challenging, but as a community, its easier to fill gaps, plan, invest and implement your financial goals and gain independence',
        bgColor: '#F3F3F6'
    },
    {
        query: 'Where to start?',
        answer: 'Ace Matrix is a members only community of finance enthusiasts and people who want to take control of their finances and achieve financial independence. Achieving the top 1% is challenging, but as a community, its easier to fill gaps, plan, invest and implement your financial goals and gain independence',
        bgColor: '#F3F3F6'
    },
    {
        query: 'How useful is Ace Matrix for an IT Professional?',
        answer: 'Ace Matrix is a members only community of finance enthusiasts and people who want to take control of their finances and achieve financial independence. Achieving the top 1% is challenging, but as a community, its easier to fill gaps, plan, invest and implement your financial goals and gain independence',
        bgColor: '#F3F3F6'
    },
    {
        query: 'How useful is Ace Matrix for a Trader?',
        answer: 'Ace Matrix is a members only community of finance enthusiasts and people who want to take control of their finances and achieve financial independence. Achieving the top 1% is challenging, but as a community, its easier to fill gaps, plan, invest and implement your financial goals and gain independence',
        bgColor: '#F3F3F6'
    },
];

export const indexHeader = ['Index Name', 'Symbol', 'CMP', 'Open Price', 'High', 'Low', 'Previous Close', '52 weeks High', '52 weeks Low']

export const futuresHeader = ['#', 'Company Name', 'Symbol', 'CMP', 'Prev. Close', 'High', 'Low', 'Difference', 'Max Intraday Profit', '52 weeks High', '52 weeks Low', 'Stock P/E', 'EPS']

export const futHeader = ['#', 'Company Name', 'Symbol', 'Lot Size', 'CMP', 'Total Value', 'Req. Margin', 'Prev. Close', 'High', 'Low', 'Difference', 'Max Intraday Profit', '52 weeks High', '52 weeks Low', 'Stock P/E', 'EPS', 'Action']

export const scriptsHeader = ['Company Name', 'Symbol', 'Market Capital', 'CMP', 'Open', 'Prev. Close', 'Change', 'Volume', 'Volume Average', 'High', 'Low', '52 weeks High', '52 weeks Low', 'Stock P/E', 'EPS']

export const subscribeHeader = ['Features' , 'Free' , 'Silver', 'Gold', 'Platinum']

export const subscribe = [
    {
        title: 'Money Facts',
        free: true,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'Goal based Financial Planning',
        free: false,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'Ace Matrix Financial Planning Tools',
        free: false,
        silver: false,
        gold: true,
        platinum: true   
    },
    {
        title: 'All about Assets and Liabilities',
        free: true,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'Cash Flow Secrets',
        free: false,
        silver: false,
        gold: true,
        platinum: true
    },
    {
        title: 'Contingency Planning',
        free: false,
        silver: false,
        gold: true,
        platinum: true
    },
    {
        title: 'Asset Allocation',
        free: false,
        silver: false,
        gold: false,
        platinum: true
    },
    {
        title: 'All about Credit Cards',
        free: false,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'All about FDs',
        free: true,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'All about ETFs',
        free: true,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'All about MFs',
        free: false,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'Insurances and Loans',
        free: true,
        silver: true,
        gold: true,
        platinum: true
    },
    {
        title: 'Retirement Planning',
        free: false,
        silver: false,
        gold: false,
        platinum: true
    },
    {
        title: 'Tax Planning',
        free: false,
        silver: false,
        gold: true,
        platinum: true
    },
]