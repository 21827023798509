import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Typography} from '@mui/material';
import {Toaster} from 'react-hot-toast';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router';
import DashLayout from '../Shared/DashLayout';
import actions from '../../redux/main/notifications/actions';

const Notifications = () => {
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_NOTIFICATIONS_START});
    };
    const classes = customStyles();
    return(
    <>
        <Toaster />
        <DashLayout component={
            <div>
                <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                    <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                    <Link href={'/user/account'} className={classes.textDecoration} > Account </Link>
                    <Link className={classes.textDecoration}> Notifications </Link>
                </Breadcrumbs>
                <Typography variant="h5" style={{color: "#CC9900", padding: 10, fontWeight: 600}}>Notifications</Typography>
                <div style={{margin: 20, marginTop: 10}}>
                    
                </div>
            </div>
        } />
    </>
    );
}

export default Notifications;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',

        '&:hover':{
            color: '#182230',
        }
    },
})
