import React, {useState} from 'react';
import {Badge, Button, Chip, Grid, Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Tab, Tabs, Typography} from '@mui/material';
import {TabContext, TabPanel} from '@mui/lab';
import {makeStyles} from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ShieldIcon from '@mui/icons-material/Shield';
import _ from 'lodash';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';
import { subscribe, subscribeHeader } from '../../../helpers/constant';


const Subscription = () => {
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 30, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h5' style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>Subscription</Typography>
                <Typography variant='h6' style={{fontSize: '14px', textAlign: 'center', color: '#182230'}}>Trusted by 10,000+ Customers</Typography>
                <Typography variant='h6' style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#FFBF00'}}>Learn</span> . <span style={{color: '#0070C0'}}>Analyse</span> . <span style={{color: '#00B050'}}>Execute</span> . <span style={{color: '#FF5733'}}>Make Fortune</span></Typography>
            </div>
            <Typography variant='h4' style={{fontsize: '28px', margin: '0.5em', color: '#00234B', textAlign: 'center'}}>Discover the best plan that suits you</Typography>
            <div style={{margin: 20, marginTop: 10}}>
                <TabContext value={value}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        fullWidth={true}
                        centered
                    >
                        <Tab label='Monthly' value={1} />
                        <Tab label='Yearly' value={2} />
                        
                    </Tabs>
                    <TabPanel value={1}>
                        <div>
                        <Grid container className={classes.flexCenter}>
                            <Grid item xs={12} sm={12} md={1.5}></Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <div style={{margin: 10, padding: 10, borderRadius: 30, textAlign: 'center', border: '1px solid rgb(234, 231, 239)'}}>
                                    <ShieldIcon fontSize='large' sx={{color: '#C0C0C0'}} />
                                    <Typography variant='h5' style={{fontSize: '22px', margin: '0.4em', color: '#0070C0', fontWeight: 600}}>Silver</Typography>
                                    <Typography variant='h5' style={{fontSize: '28px', margin: 5, color: '#00B050', fontWeight: 600}}>₹999</Typography>
                                    <Button variant='contained' href='/payment' style={{fontSize: '14px', margin: '0.4em', borderRadius: 20}}>Subscribe</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <div style={{margin: 10, padding: 10, borderRadius: 30, textAlign: 'center', border: '1px solid rgb(234, 231, 239)'}}>
                                    <ShieldIcon fontSize='large' sx={{color: '#FFD700'}} />
                                    <Typography variant='h5' style={{fontSize: '22px', margin: '0.4em', color: '#0070C0', fontWeight: 600}}>Gold</Typography>
                                    <Typography variant='h5' style={{fontSize: '28px', margin: 5, color: '#00B050', fontWeight: 600}}>₹1,999</Typography>
                                    <Button variant='contained' href='/payment' style={{fontSize: '14px', margin: '0.4em', borderRadius: 20}}>Subscribe</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <div style={{margin: 10, padding: 10, borderRadius: 30, textAlign: 'center', border: '1px solid rgb(234, 231, 239)'}}>
                                    <ShieldIcon fontSize='large' sx={{color: '#E5E4E2'}} />
                                    <Typography variant='h5' style={{fontSize: '22px', margin: '0.4em', color: '#0070C0', fontWeight: 600}}>Platinum</Typography>
                                    <Typography variant='h5' style={{fontSize: '28px', margin: 5, color: '#00B050', fontWeight: 600}}>₹3,999</Typography>
                                    <Button variant='contained' href='/payment' style={{fontSize: '14px', margin: '0.4em', borderRadius: 20}}>Subscribe</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1.5}></Grid>
                        </Grid>
                        </div>
                    </TabPanel>
                    <TabPanel value={2}>
                        <div>  
                        <Grid container className={classes.flexCenter}>
                        <Grid item xs={12} sm={12} md={1.5}></Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <div style={{margin: 10, padding: 10, borderRadius: 30, textAlign: 'center', border: '1px solid rgb(234, 231, 239)'}}>
                                    <ShieldIcon fontSize='large' sx={{color: '#C0C0C0'}} />
                                    <Typography variant='h5' style={{fontSize: '22px', margin: '0.4em', color: '#0070C0', fontWeight: 600}}>Silver</Typography>
                                    <Typography variant='h5' style={{fontSize: '28px', margin: 5, color: '#00B050', fontWeight: 600}}>₹9,990</Typography>
                                    <Button variant='contained' href='/payment' style={{fontSize: '14px', margin: '0.4em', borderRadius: 20}}>Subscribe</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <div style={{margin: 10, padding: 10, borderRadius: 30, textAlign: 'center', border: '1px solid rgb(234, 231, 239)'}}>
                                    <ShieldIcon fontSize='large' sx={{color: '#FFD700'}} />
                                    <Typography variant='h5' style={{fontSize: '22px', margin: '0.4em', color: '#0070C0', fontWeight: 600}}>Gold</Typography>
                                    <Typography variant='h5' style={{fontSize: '28px', margin: 5, color: '#00B050', fontWeight: 600}}>₹19,990</Typography>
                                    <Button variant='contained' href='/payment' style={{fontSize: '14px', margin: '0.4em', borderRadius: 20}}>Subscribe</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <div style={{margin: 10, padding: 10, borderRadius: 30, textAlign: 'center', border: '1px solid rgb(234, 231, 239)'}}>
                                    <ShieldIcon fontSize='large' sx={{color: '#E5E4E2'}} />
                                    <Typography variant='h5' style={{fontSize: '22px', margin: '0.4em', color: '#0070C0', fontWeight: 600}}>Platinum</Typography>
                                    <Typography variant='h5' style={{fontSize: '28px', margin: 5, color: '#00B050', fontWeight: 600}}>₹39,990</Typography>
                                    <Button variant='contained' href='/payment' style={{fontSize: '14px', margin: '0.4em', borderRadius: 20}}>Subscribe</Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1.5}></Grid>
                        </Grid>    
                        </div>
                    </TabPanel>
                </TabContext>
                <Typography variant='h5' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0, textAlign: 'center'}}>Plan Comparison</Typography>           
                <TableContainer component={Paper} style={{borderRadius: 30, padding: 10}}>
                    <Table sx={{minWidth: 400}}>
                        <TableHead>
                            <TableRow>
                                {subscribeHeader.map(head => (
                                    <TableCell sx={{fontWeight: 600, fontSize: '18px'}}>{head}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subscribe.map((data) => (
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell>{data.title}</TableCell>
                                    <TableCell>{data.free ? <CheckIcon style={{margin: '0.25em'}} /> : <CloseIcon style={{margin: '0.25em'}} />}</TableCell>
                                    <TableCell>{data.silver ? <CheckIcon style={{margin: '0.25em'}} /> : <CloseIcon style={{margin: '0.25em'}} />}</TableCell>
                                    <TableCell>{data.gold ? <CheckIcon style={{margin: '0.25em'}} /> : <CloseIcon style={{margin: '0.25em'}} />}</TableCell>
                                    <TableCell>{data.platinum ? <CheckIcon style={{margin: '0.25em'}} /> : <CloseIcon style={{margin: '0.25em'}} />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Footer />
        </>
    );
}

export default Subscription;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        color: '#0c5aa5',
        padding: '50px 0',
        textAlign: 'center',
        fontWeight: 500,
        backgroundColor: '#F7F7F7'
    },
    profileCardStyle: {
        // margin: '12px 20px',
        // width: '100%',
        borderRadius: 10,
        // border: '1px solid #00a03a',
        boxShadow: 'none',
        backgroundColor: '#FFF7E7',
    },
    margin8a: {
        margin: '10px',
    }, 
    textDecoration: {
        textDecoration: 'none'
    },
    media: {
        height: 250,
        // width: 250,
        // paddingTop: '56.25%',
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0 0 25px 5px',
        boxShadow: 'none',
        borderRadius: 10,
        backgroundColor: '#00a03a',
        color: '#f7f7f7',
        border: '1px solid #00a03a',
        fontWeight: 600,

        '&:hover': {
            backgroundColor: '#FFF7E7',
            color: '#000000',
            border: '1px solid #FFF7E7',
            boxShadow: 'none',
        },
    },
})
