const actions = {
    USER_REGISTRATION_START:'USER_REGISTRATION_START',
    USER_REGISTRATION_SUCCESS: 'USER_REGISTRATION_SUCCESS',
    USER_REGISTRATION_FAILURE: 'USER_REGISTRATION_FAILURE',
    USER_REGISTRATION_GOOGLE_START:'USER_REGISTRATION_GOOGLE_START',
    USER_REGISTRATION_GOOGLE_SUCCESS: 'USER_REGISTRATION_GOOGLE_SUCCESS',
    USER_REGISTRATION_GOOGLE_FAILURE: 'USER_REGISTRATION_GOOGLE_FAILURE',
    LOGIN_START:'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    GOOGLE_LOGIN_START:'GOOGLE_LOGIN_START',
    GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
    GOOGLE_LOGIN_FAILURE: 'GOOGLE_LOGIN_FAILURE',
    LOGOUT_START:'LOGOUT_START',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    GET_USER_DETAILS_START: 'GET_USER_DETAILS_START',
    GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
    GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',
    EDIT_USER_DETAILS_START: 'EDIT_USER_DETAILS_START',
    EDIT_USER_DETAILS_SUCCESS: 'EDIT_USER_DETAILS_SUCCESS',
    EDIT_USER_DETAILS_FAILURE: 'EDIT_USER_DETAILS_FAILURE',
};

export default actions;
