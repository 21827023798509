import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Link, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Toaster} from 'react-hot-toast';
import DashLayout from '../../Shared/DashLayout';


const Clan = () => {
  const [value, setValue] = useState(1);
  const handleChange = (e, value) => {
      setValue(value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
      loadTasks();
  }, []);
  const loadTasks = () => {
      // dispatch({type: actions.GET_PROPERTIES_START});
  }
  const loadProperty = (value) => {
      // dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
  };
  const classes = customStyles();
  return(
    <>
      <Toaster />
      <DashLayout component={
          <div>
              <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                  <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                  <Link className={classes.textDecoration}> Clan </Link>
              </Breadcrumbs>
              <Typography variant='h5' style={{color: '#9900CC', padding: 10, fontWeight: 600}}>Clan</Typography>
              <div style={{margin: 20, marginTop: 10}}>
                <Typography variant='h5' style={{paddingLeft: 10, fontWeight: 600}}>Debentures</Typography>
                <Typography variant='h6' style={{paddingLeft: 10, fontWeight: 500}}>A debenture is a type of debt instrument issued by companies to raise capital. It is not secured by physical assets or collateral. Debentures promise to pay interest and principal to the debenture holders. Companies issue debentures to investors, and these investors become creditors of the company.</Typography>
              </div>
          </div>
      } />
    </>
  );
}

export default Clan;

const customStyles = makeStyles({
  textDecoration: {
    textDecoration: "none",
    color: '#7F9082',

    '&:hover':{
        color: '#182230',
    }
  },
})
