import React, {useEffect, useState} from 'react'
import {Card, CardMedia, CardContent, CardActions, Collapse, Checkbox, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Paper, Typography, Button} from '@mui/material';
import {ContentCut, ContentCopy, ContentPaste, Cloud, ExpandLess, ExpandMore, SubscriptionsOutlined} from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import {useDispatch, useSelector} from 'react-redux';
import actions from '../../../../redux/main/scripts/actions';
import {makeStyles} from '@mui/styles';

const Pursuit = () => {
    const [subscribeOpen, setSubscribeOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [levelOpen, setLevelOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [ratingOpen, setRatingOpen] = useState(false);
    const [subscribe, setSubscribeFilter] = useState({silver: false, gold: false, platinum: false});
    const [category, setCategoryFilter] = useState({personalFinances: false, longTerm: false, shortTerm: false, mutualFunds: false, insurances: false});
    const [level, setLevelFilter] = useState({beginner: false, intermediate: false, professional: false});
    const [language, setLanguageFilter] = useState({english: false, tamil: false});
    const [rating, setRatingFilter] = useState({fiveStars: false, fourStars: false, threeStars: false});

    const subscribeFilter = (event) => {
        setSubscribeFilter({
          ...subscribe,
          [event.target.name]: event.target.checked,
        });
    };
    const categoryFilter = (event) => {
        setCategoryFilter({
          ...category,
          [event.target.name]: event.target.checked,
        });
    };
    const levelFilter = (event) => {
        setLevelFilter({
          ...level,
          [event.target.name]: event.target.checked,
        });
    };
    const languageFilter = (event) => {
        setLanguageFilter({
          ...language,
          [event.target.name]: event.target.checked,
        });
    };
    const ratingFilter = (event) => {
        setRatingFilter({
          ...rating,
          [event.target.name]: event.target.checked,
        });
    };
    const handleSubscribe = () => {
        setSubscribeOpen(!subscribeOpen);
    };
    const handleCategory = () => {
        setCategoryOpen(!categoryOpen );
    };
    const handleLevel = () => {
        setLevelOpen(!levelOpen);
    };
    const handleLanguage = () => {
        setLanguageOpen(!languageOpen);
    };
    const handleRating = () => {
        setRatingOpen(!ratingOpen);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_SCRIPTS_START,
        });
    };
    const getFilteredResult = () => {
        dispatch({
            type: actions.GET_SCRIPTS_START,

        });
    };
    const {silver, gold, platinum} = subscribe;
    const {personalFinances, shortTerm, longTerm, mutualFunds, insurances} = category;
    const {beginner, intermediate, professional} = level;
    const {english, tamil} = language;
    const {fiveStars, fourStars, threeStars} = rating;
    const classes = customStyles();
    return(
        <div style={{padding: 20}}>
            <div style={{paddingBottom: 20}}>
                <Typography variant='h4' style={{color: '#0c5aa5', paddingLeft: '1.5rem', fontWeight: 600}}>Pursuit</Typography>
                <Typography variant='h6' style={{color: '#000', paddingLeft: '1.5rem', fontSize: '20px', fontWeight: 400}}>Gain knowledge with our courses</Typography>
            </div>
            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper style={{maxWidth: '100%', border: '1px solid rgb(234, 231, 239)', borderRadius: 30, boxShadow: 'none', padding: 10}}>
                        <List component='nav'subheader={<ListSubheader component='div'>Course Filter</ListSubheader>}>
                            <ListItemButton onClick={handleSubscribe}>
                                <ListItemText primary='Subscriptions' />
                                {subscribeOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={subscribeOpen} timeout='auto' unmountOnExit>
                            {[{title: 'Silver', slug: 'silver'},{title: 'Gold', slug: 'gold'},{title: 'Platinum', slug: 'platinum'}].map((filter) => {
                                const labelId = filter.slug;
                                return (
                                <ListItem
                                    component='div' 
                                    secondaryAction={
                                        <Checkbox
                                            edge='end'
                                            onChange={subscribeFilter}
                                            checked={filter.slug === 'silver' ? silver : filter.slug === 'gold' ? gold : platinum}
                                            name={filter.slug}
                                        />
                                    } 
                                    style={{paddingLeft: 20}}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText id={labelId} primary={filter.title} />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </Collapse>

                            <ListItemButton onClick={handleCategory}>
                                <ListItemText primary='Categories' />
                                {categoryOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={categoryOpen} timeout='auto' unmountOnExit>
                            {[{title: 'Personal Finances', slug: 'personalFinances'},{title: 'Long Term Investing', slug: 'longTerm'},{title: 'Short Term Investing', slug: 'shortTerm'},{title: 'Mutual Funds', slug: 'mutualFunds'},{title: 'Insurances', slug: 'insurances'}].map((filter) => {
                                const labelId = filter.slug;
                                return (
                                <ListItem
                                    component='div' 
                                    secondaryAction={
                                        <Checkbox
                                            edge='end'
                                            onChange={categoryFilter}
                                            checked={filter.slug === 'personalFinances' ? personalFinances : filter.slug === 'shortTerm' ? shortTerm : filter.slug === 'longTerm' ? longTerm : filter.slug === 'mutualFunds' ? mutualFunds : insurances}
                                            name={filter.slug}
                                        />
                                    } 
                                    style={{paddingLeft: 20}}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText id={labelId} primary={filter.title} />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </Collapse>

                            <ListItemButton onClick={handleLevel}>
                                <ListItemText primary='Levels' />
                                {levelOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={levelOpen} timeout='auto' unmountOnExit>
                            {[{title: 'Beginner', slug: 'beginner'},{title: 'Intermediate', slug: 'intermediate'},{title: 'Professional', slug: 'professional'}].map((filter) => {
                                const labelId = filter.slug;
                                return (
                                <ListItem
                                    component='div' 
                                    secondaryAction={
                                        <Checkbox
                                            edge='end'
                                            onChange={levelFilter}
                                            checked={filter.slug === 'beginner' ? beginner : filter.slug === 'intermediate' ? intermediate : professional}
                                            name={filter.slug}
                                        />
                                    } 
                                    style={{paddingLeft: 20}}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText id={labelId} primary={filter.title} />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </Collapse>

                            <ListItemButton onClick={handleLanguage}>
                                <ListItemText primary='Languages' />
                                {languageOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={languageOpen} timeout='auto' unmountOnExit>
                            {[{title: 'English', slug: 'english'},{title: 'Tamil', slug: 'tamil'}].map((filter) => {
                                const labelId = filter.slug;
                                return (
                                <ListItem
                                    component='div' 
                                    secondaryAction={
                                        <Checkbox
                                            edge='end'
                                            onChange={languageFilter}
                                            checked={filter.slug === 'english' ? english : tamil}
                                            name={filter.slug}
                                        />
                                    } 
                                    style={{paddingLeft: 20}}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText id={labelId} primary={filter.title} />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </Collapse>

                            <ListItemButton onClick={handleRating}>
                                <ListItemText primary='Ratings' />
                                {ratingOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={ratingOpen} timeout='auto' unmountOnExit>
                            {[{title: '5 Stars', slug: 'fiveStars'},{title: '4 Stars', slug: 'fourStars'},{title: '3 Stars', slug: 'threeStars'}].map((filter) => {
                                const labelId = filter.slug;
                                return (
                                <ListItem
                                    component='div' 
                                    secondaryAction={
                                        <Checkbox
                                            edge='end'
                                            onChange={ratingFilter}
                                            checked={filter.slug === 'fourStars' ? fourStars : filter.slug === 'threeStars' ? threeStars : fiveStars}
                                            name={filter.slug}
                                        />
                                    } 
                                    style={{paddingLeft: 20}}
                                    disablePadding
                                >
                                    <ListItemButton>
                                        <ListItemText id={labelId} primary={filter.title} />
                                    </ListItemButton>
                                </ListItem>
                                );
                            })}
                            </Collapse>
                            <div style={{textAlign: 'center', padding: 10}}>
                                <Button variant='contained' onClick={getFilteredResult}>Show Results</Button>
                            </div>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={9}>  
                    <Grid container spacing={2} style={{padding: 20}}>
                        <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                            <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                                <CardMedia
                                    sx={{height: 175}}
                                    image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                    title='Demo Course Title'
                                />
                                <CardContent>
                                    <Typography variant='h5'>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant='h6'>
                                                <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{justifyContent: 'right'}}>
                                            <Button variant='outlined'>Go To Course</Button>
                                        </Grid>
                                    </Grid>                                    
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                            <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                                <CardMedia
                                    sx={{height: 175}}
                                    image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                    title='Demo Course Title'
                                />
                                <CardContent>
                                    <Typography variant='h5'>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant='h6'>
                                                <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{justifyContent: 'right'}}>
                                            <Button variant='outlined'>Go To Course</Button>
                                        </Grid>
                                    </Grid>                                    
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                            <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                                <CardMedia
                                    sx={{height: 175}}
                                    image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                    title='Demo Course Title'
                                />
                                <CardContent>
                                    <Typography variant='h5'>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant='h6'>
                                                <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{justifyContent: 'right'}}>
                                            <Button variant='outlined'>Go To Course</Button>
                                        </Grid>
                                    </Grid>                                    
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                            <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                                <CardMedia
                                    sx={{height: 175}}
                                    image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                    title='Demo Course Title'
                                />
                                <CardContent>
                                    <Typography variant='h5'>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                        Stock Market for Beginner
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <Divider />
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom variant='h6'>
                                                <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} style={{justifyContent: 'right'}}>
                                            <Button variant='outlined'>Go To Course</Button>
                                        </Grid>
                                    </Grid>                                    
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Pursuit;

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: '40px',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
