import React from 'react';
import {Link} from 'react-router';
import {Button, Grid, Paper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {InstagramEmbed} from 'react-social-media-embed';
import {YouTubeEmbed} from 'react-social-media-embed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';
import {products} from '../../helpers/constant';
import FAQ from './FAQ';


const Home = () => {
    const classes = customStyles();
    return (
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{backgroundColor: '#E6F2FF', padding: 5, margin: 20, borderRadius: 12}}>
                <div style={{color: '#090909', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>
                    <span style={{color: '#1A56DB', fontWeight: 600}}>'Money is a terrible master but an excellent servant!'</span> is now trending... <Link to={'/courses'} style={{color: '#090909'}}><Button disableRipple className={classes.postBtn} style={{margin: '0 10px'}}> Enroll Now </Button></Link>
                </div>
            </div>

            <div style={{textAlign: 'center', padding: 20, margin: 20, borderRadius: 15, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h4' style={{color: '#182230', fontWeight: 600, fontSize: '28px'}}>Easing People to attain Financial Freedom</Typography>
                <Typography variant='h6' style={{fontSize: '18px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#1A56DB'}}>Learn</span> . <span style={{color: '#1A56DB'}}>Analyse</span> . <span style={{color: '#1A56DB'}}>Execute</span> . <span style={{color: '#1A56DB'}}>Make Fortune</span></Typography>
                <Link to={'/courses'} style={{color: '#090909'}}>
                    <Button disableRipple className={classes.postBtn}>
                        Partnership
                    </Button>
                </Link>
                <Link to={'/courses'} style={{color: '#090909'}}>
                    <Button disableRipple className={classes.postBtn}>
                        Mentorship
                    </Button>
                </Link>
            </div>

            <div style={{padding: 10}}>
                <Typography variant='h4' style={{color: '#1A56DB', paddingLeft: '1.5rem', textAlign: 'center', fontSize: '28px', fontWeight: 600}}>What We Provide</Typography>
                <Typography variant='h6' style={{color: '#182230', paddingLeft: '1.5rem', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>Learn savings to investing from us</Typography>
                <Grid container>
                    <Grid item xs={12} sm={4} md={3} lg={2} key={1}>
                        <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 15, border: '1px solid rgb(234, 231, 239)', textAlign: 'center'}}>
                            <CalendarMonthIcon  fontSize='large' sx={{color: '#1A56DB'}} />
                            <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px'}}>
                                Budgeting
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2} key={1}>
                        <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 15, border: '1px solid rgb(234, 231, 239)', textAlign: 'center'}}>
                            <CreditScoreIcon fontSize='large' sx={{color: '#1A56DB'}} />
                            <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px', textAlign: 'center'}}>
                                Potential Investments
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2} key={1}>
                        <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 15, border: '1px solid rgb(234, 231, 239)', textAlign: 'center'}}>
                            <RequestQuoteOutlinedIcon fontSize='large' sx={{color: '#1A56DB'}} />
                            <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px', textAlign: 'center'}}>
                                Asset Allocations
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2} key={1}>
                        <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 15, border: '1px solid rgb(234, 231, 239)', textAlign: 'center'}}>
                            <CurrencyExchangeOutlinedIcon fontSize='large' sx={{color: '#1A56DB'}} />
                            <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px', textAlign: 'center'}}>
                                Debt Proposing
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2} key={1}>
                        <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 15, border: '1px solid rgb(234, 231, 239)', textAlign: 'center'}}>
                            <MedicalInformationOutlinedIcon fontSize='large' sx={{color: '#1A56DB'}} />
                            <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px', textAlign: 'center'}}>
                                Insurance Design
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2} key={1}>
                        <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 15, border: '1px solid rgb(234, 231, 239)', textAlign: 'center'}}>
                            <Diversity1OutlinedIcon fontSize='large' sx={{color: '#1A56DB'}} />
                            <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px', textAlign: 'center'}}>
                                Retirement Secrecy
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </div>

            <div style={{padding: 10}}>
                <Typography variant='h4' style={{color: '#1A56DB', paddingLeft: '1.5rem', textAlign: 'center', fontSize: '28px', fontWeight: 600}}>Products</Typography>
                <Typography variant='h6' style={{color: '#182230', paddingLeft: '1.5rem', textAlign: 'center', fontSize: '20px', fontWeight: 400}}>We solve your problems</Typography>
                <Grid container>
                    {products.map((data) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={data.companyName}>
                            <Paper style={{margin: '0.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 12, border: '1px solid rgb(234, 231, 239)'}}>
                                <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px', }}>
                                    {data.title}
                                </Typography>
                                <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '14px'}}>
                                    {data.slug}
                                </Typography>
                                <Grid container >
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant='h6' style={{fontSize: '16px', padding: '10px 0'}}>
                                            {data.shortDesc}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Button href={`${data.link}`} variant='outlined' style={{color: '#1A56DB', border: '2px solid #1A56DB', fontSize: '12px', fontWeight: 600}}>
                                            {data.btnTitle}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>

            {/* <Grid style={{padding: 10}}>
                <Typography variant='h4' style={{color: '#1A56DB', padding: '1.5rem', fontSize: '28px', fontWeight: 600}}>News Feeds</Typography>
                <Grid container>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <InstagramEmbed url='https://www.instagram.com/p/DEZ2nBvNUFv/' />    
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <InstagramEmbed url='https://www.instagram.com/p/DEXK4wMtEfX/' />    
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <InstagramEmbed url='https://www.instagram.com/p/DEKr5bjy4gt/' />    
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{padding: 10}}>
                <Typography variant='h4' style={{color: '#1A56DB', padding: '1.5rem', textAlign: 'left', fontSize: '28px', fontWeight: 600}}>Video Content</Typography>
                <Grid container>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <YouTubeEmbed url='https://www.youtube.com/watch?v=RfOKl-ya5BY' width={325} height={220} />   
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <YouTubeEmbed url='https://www.youtube.com/watch?v=GmsCvpYE50c' width={325} height={220} />  
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <YouTubeEmbed url='https://www.youtube.com/watch?v=m4WOwgUMQuc' width={325} height={220} />  
                        </div>
                    </Grid>
                </Grid>
            </Grid> */}
            <FAQ />
            <Footer />
        </>
    );
};

export default Home;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    padding8: {
        padding: 8,
    },
    postBtn: {
        minWidth: 100,
        margin: '5px',
        padding: '5px 20px',
        boxShadow: 'none',
        borderRadius: 12,
        color: '#FFF',
        backgroundColor: '#1A56DB',

        '&:hover': {
            color: '#1A56DB',
            boxShadow: 'none',
            backgroundColor: '#E8EFFC',
        },
    },
    textDecoration: {
        color: '#182230',
        textDecoration: 'none'
    },
    h6Text: {
        fontSize: '16px',
        fontWeight: 600, 
    }
})
