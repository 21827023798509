import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Button, Chip, Grid, Link, Rating, Tabs, Tab, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Toaster} from 'react-hot-toast';
import DashLayout from '../../Shared/DashLayout';
import actions from '../../../redux/main/pursuit/actions';


const CourseDetails = () => {
  const {courseId} = useParams();
  const {courseDetails} = useSelector(state => state.pursuitReducer);
  const dispatch = useDispatch();
  useEffect(() => {
      loadTasks();
  }, []);
  const loadTasks = () => {
      dispatch({type: actions.GET_COURSE_DETAILS_START, id: courseId});
  };
  const classes = customStyles();
  console.log(courseDetails)
  return(
    <>
      <Toaster />
      <DashLayout component={
          <div>
              <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                  <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                  <Link href={'/user/pursuit'} className={classes.textDecoration} > Pursuit </Link>
                  <Link className={classes.textDecoration}> {courseDetails.title} </Link>
              </Breadcrumbs>
              <div style={{margin: 20, marginTop: 20}}>
                <Grid container>
                  <Grid item sm={12} md={6}> 
                    <Chip label={courseDetails.categories.title} style={{fontSize: '16px'}} />
                    <Typography variant="h4" style={{paddingTop: 10, fontWeight: 600}}>{courseDetails.title}</Typography>
                    <Typography variant="h5" style={{fontWeight: 500, color: '#7F9082'}}>{courseDetails.description}</Typography>
                    <Rating value={courseDetails.rating} precision={0.5} readOnly />
                    <Button variant='contained'>Enroll Now</Button>
                  </Grid>
                  <Grid item sm={12} md={6}> 
                    <img src={'https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'} width='100%' />
                  </Grid>
                </Grid>
              </div>
          </div>
      } />
    </>
  );
}

export default CourseDetails;

const customStyles = makeStyles({
  textDecoration: {
    textDecoration: "none",
    color: '#7F9082',

    '&:hover':{
        color: '#182230',
    }
  },
})
