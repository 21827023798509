import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Card, CardMedia, CardContent, CardActions, Divider, Grid, Link, Tab, Tabs, Typography, Button} from '@mui/material';
import {ExpandLess, ExpandMore, SubscriptionsOutlined} from '@mui/icons-material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import {makeStyles} from '@mui/styles';
import {Toaster} from 'react-hot-toast';
import actions from '../../../redux/main/pursuit/actions';


const Courses = (props) => {
  const {courses} = props;
  const [value, setValue] = useState(1);
  const handleChange = (e, value) => {
      setValue(value);
  };
  const dispatch = useDispatch();
  const loadCourseDetails = (value) => {
      dispatch({type: actions.GET_COURSE_DETAILS_START, courseId: value});
  };
  const classes = customStyles();
  return(
    <>
    <Toaster />
    <TabContext value={value}>
        <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable-tabs"
        >
            <Tab label="My Courses" value={1} />
            <Tab label="Free Courses" value={2} />
            <Tab label="Paid Courses" value={3} />
        </Tabs>
    
        <TabPanel value={1}>
            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>My Courses</Typography>
            <div> 
                <Grid container spacing={2} style={{padding: 20}}>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                        {courses.map((data) => (
                        <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                            <CardMedia
                                sx={{height: 175}}
                                image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                title={data.title}
                            />
                            <CardContent>
                                <Typography variant='h5'>
                                    {data.title}
                                </Typography>
                                <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                    {data.shortDesc}
                                </Typography>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom variant='h6'>
                                            <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹{data.offerPrice}</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹{data.maxPrice}</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{justifyContent: 'right'}}>
                                        <Link href={`/user/pursuit/${data.id}`}><Button variant='outlined' onClick={() => loadCourseDetails(data.id)}>Go To Course</Button></Link>
                                    </Grid>
                                </Grid>                                    
                            </CardActions>
                        </Card>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                        <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                            <CardMedia
                                sx={{height: 175}}
                                image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                title='Demo Course Title'
                            />
                            <CardContent>
                                <Typography variant='h5'>
                                    Stock Market for Beginner
                                </Typography>
                                <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                    Stock Market for Beginner
                                </Typography>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom variant='h6'>
                                            <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{justifyContent: 'right'}}>
                                        <Button variant='outlined'>Go To Course</Button>
                                    </Grid>
                                </Grid>                                    
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                        <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                            <CardMedia
                                sx={{height: 175}}
                                image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                title='Demo Course Title'
                            />
                            <CardContent>
                                <Typography variant='h5'>
                                    Stock Market for Beginner
                                </Typography>
                                <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                    Stock Market for Beginner
                                </Typography>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom variant='h6'>
                                            <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{justifyContent: 'right'}}>
                                        <Button variant='outlined'>Go To Course</Button>
                                    </Grid>
                                </Grid>                                    
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                        <Card sx={{maxWidth: 345, border: '1px solid rgb(234, 231, 239)', borderRadius: 5, boxShadow: 'none'}}>
                            <CardMedia
                                sx={{height: 175}}
                                image='https://www.globalcareercounsellor.com/blog/wp-content/uploads/2018/05/Online-Career-Counselling-course.jpg'
                                title='Demo Course Title'
                            />
                            <CardContent>
                                <Typography variant='h5'>
                                    Stock Market for Beginner
                                </Typography>
                                <Typography variant='h6' style={{fontSize: '16px', marginBottom: 10}}>
                                    Stock Market for Beginner
                                </Typography>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'left'}}><Typography>Ratings</Typography></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{textAlign: 'right'}}><Typography>By Ace Matrix</Typography></span>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography gutterBottom variant='h6'>
                                            <span style={{fontSize: '18px', padding: '0 10px 0 10px'}}>₹999</span><span style={{fontSize: '18px', color: '#7F90A2', textDecoration: 'line-through'}}>₹1,499</span>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{justifyContent: 'right'}}>
                                        <Button variant='outlined'>Go To Course</Button>
                                    </Grid>
                                </Grid>                                    
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>   
            </div>
        </TabPanel>
        <TabPanel value={2}>
            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Free Courses</Typography>
            <div>  
                <Typography variant="h6" style={{padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
            </div>
        </TabPanel>
        <TabPanel value={3}>
            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Paid Courses</Typography>
            <div>
                <Typography variant="h6" style={{padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
            </div>
        </TabPanel>
    </TabContext>
    </>
  );
}

export default Courses;

const customStyles = makeStyles({
  textDecoration: {
    textDecoration: "none",
    color: '#7F9082',

    '&:hover':{
        color: '#182230',
    }
  },
})
