import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Grid, Paper, Tabs, Tab, Typography} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router';
import clsx from 'clsx';
import actions from '../../../redux/main/clutch/actions';


const Portfolios = () => {
    const {portfolios} = useSelector(state => state.clutchReducer);
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_PORTFOLIOS_START});
    }
    const loadProperty = (value) => {
        dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const classes = customStyles();
    return(
        <>
            <div style={{margin: 20, marginTop: 10}}>
                <TabContext value={value}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable-tabs"
                    >
                        <Tab label="My Watchlist" value={1} />
                        <Tab label="Ace Matrix" value={2} />
                        <Tab label="Free Ace Matrix" value={3} />
                        <Tab label="Premium Ace Matrix" value={4} />
                    </Tabs>
                
                    <TabPanel value={1} style={{padding: 0}}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Portfolios</Typography>
                        <div>
                            <Grid container>
                                {portfolios.map((data) => (
                                <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                                    <Paper style={{margin: '0.5rem', padding: '1rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                        <Typography variant="h6" style={{fontWeight: 600, fontSize: '18px'}}>
                                            {data.title}
                                        </Typography>
                                        <Typography variant="h6" style={{fontWeight: 600, color: '#7F90A2', fontSize: '14px'}}>
                                            Benchmark - {data.benchMark} | Category - {data.category}
                                        </Typography>
                                        <Grid container style={{borderRadius: 20, backgroundColor: '#F1F4F9', margin: '10px 0'}}>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Total No. of Stocks
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    {data.totalNoOfStocks}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Capital
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    {data.preferredCapital}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Asset Type
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    {data.category}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Volatility
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    {data.volatility}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={8} lg={8} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Rebalance Frequency
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    {data.rebalanceFrequency}
                                                </Typography>
                                            </Grid>                                            
                                        </Grid>
                                        <Grid container >
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 5, fontSize: '16px'}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    ₹{data.subscriptionPerMonth}/month
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 5, textAlign: 'right', fontSize: '16px'}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    <Link to={`/portfolio/${data.slug}`} className={clsx(classes.textDecoration)}>
                                                        Learn More
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                ))}
                                {/* <Grid item xs={12} sm={6} md={4} lg={3} key={1}>
                                    <Paper style={{margin: '0.5rem', padding: '1rem', color:'#1842B6', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>
                                        <Typography variant="h6" style={{fontWeight: 600, fontSize: '18px'}}>
                                            Ace Matrix Nifty ETF
                                        </Typography>
                                        <Typography variant="h6" style={{fontWeight: 600, color: '#7F90A2', fontSize: '14px'}}>
                                            Benchmark - ETFs
                                            Category - Index
                                        </Typography>
                                        <Grid container style={{borderRadius: 20, backgroundColor: '#F1F4F9', margin: '10px 0'}}>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Total No. of Stocks
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    3
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Capital
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    2,00,000
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Asset Type
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    Equity
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Volatility
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                                    High
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={8} lg={8} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    Rebalance Frequency
                                                </Typography>
                                                <Typography variant="h6" className={classes.padding8} style={{color: '#34A853', fontWeight: 600}}>
                                                    3 months
                                                </Typography>
                                            </Grid>                                            
                                        </Grid>
                                        <Grid container >
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    ₹99/month
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} lg={6} style={{padding: 10, textAlign: 'right'}}>
                                                <Typography variant="subtitle" align="center" className={classes.padding8}>
                                                    <Link to={`/portfolio/id`} className={clsx(classes.textDecoration)}>
                                                        Learn More
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid> */}
                            </Grid>
                        </div>
                    </TabPanel>
                    <TabPanel value={2}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Ace Matrix</Typography>
                        <div>  
                            <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                        </div>
                    </TabPanel>
                    <TabPanel value={3}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Free Ace Matrix</Typography>
                        <div>
                            <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                        </div>
                    </TabPanel>
                    <TabPanel value={4}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Premium Ace Matrix</Typography>
                        <div>
                            <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
        </>
    );
}

export default Portfolios;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        color: '#0c5aa5',
        padding: "50px 0",
        textAlign: "center",
        fontWeight: 500,
        backgroundColor: '#F7F7F7'
    },
    profileCardStyle: {
        borderRadius: 10,
        boxShadow: "none",
        backgroundColor: "#FFF7E7",
    },
    margin8a: {
        margin: "10px",
    }, 
    textDecoration: {
        textDecoration: "none"
    },
    media: {
        height: 250,
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#FFF7E7",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
})
