import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {makeStyles} from "@mui/styles";
import _ from 'lodash';
import {futuresHeader} from '../../../../helpers/constant';
import actions from '../../../../redux/main/scripts/actions';


export const FuturesTable = (props) => {
    const {scripts} = props
    const [visible, setVisible] = useState(false);
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    const loadDetails = (value) => {
        dispatch({type: actions.GET_SCRIPT_DETAILS_START, symbol: value});
    };
    const classes = customStyles();
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }}>
                    <TableHead>
                        <TableRow>
                            {futuresHeader.map(head => (
                                <TableCell sx={{fontWeight: 600}}>{head}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scripts.map((data) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{data.rank}</TableCell>
                                <TableCell>
                                    {/* <Link
                                        to={`/discover/${data.symbol}`}
                                        className={clsx(classes.margin8a, classes.textDecoration)}
                                    > */}
                                        {data.companyName}
                                    {/* </Link> */}
                                </TableCell>
                                <TableCell>{data.symbol}</TableCell>
                                <TableCell>{data.cmp}</TableCell>
                                <TableCell>{data.prevClose}</TableCell>
                                <TableCell>{data.high}</TableCell>
                                <TableCell>{data.low}</TableCell>
                                <TableCell>{_.round((data.difference), 2)}</TableCell>
                                <TableCell>{_.round((data.maxIntradayProfit), 2)}</TableCell>
                                <TableCell>{data.l52wHigh}</TableCell>
                                <TableCell>{data.l52wLow}</TableCell>
                                <TableCell>{data.stockPE}</TableCell>
                                <TableCell>{data.eps}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
})
