import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {indexHeader} from '../../../../helpers/constant';
import {useState} from 'react';
import {makeStyles} from "@mui/styles";


export const IndicesTable = (props) => {
    const {indices} = props
    const [visible, setVisible] = useState(false);
    const handleClose = () => {
        setVisible(false);
    };
    const classes = customStyles();
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }}>
                    <TableHead>
                        <TableRow>
                            {indexHeader.map(head => (
                                <TableCell sx={{fontWeight: 600}}>{head}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {indices.map((data) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {/* <Link
                                    to={`/index/${data.id}`}
                                > */}
                                        <TableCell>{data.index}</TableCell>
                                {/* </Link>     */}
                                <TableCell>{data.symbol}</TableCell>
                                <TableCell>{data.cmp}</TableCell>
                                <TableCell>{data.openPrice}</TableCell>
                                <TableCell>{data.high}</TableCell>
                                <TableCell>{data.low}</TableCell>
                                <TableCell>{data.prevClose}</TableCell>
                                <TableCell>{data.l52wHigh}</TableCell>
                                <TableCell>{data.l52wLow}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
})
