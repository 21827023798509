import {all, call, put, take, takeEvery} from 'redux-saga/effects';
import {collection, getDocs, getDoc, setDoc, doc, query, where, orderBy, limit} from 'firebase/firestore';
import actions from './actions';
import {fbFirestore} from '../../../helpers/firebaseClient';

const auth = JSON.parse(window.sessionStorage.getItem('auth'));


export function* createPortfolio(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'ad', `${'RE' + randNum}`), params.payload));
        yield put({ type: actions.CREATE_PORTFOLIOS_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_PORTFOLIOS_FAILURE });
        return error;
    }
}

export function* getPortfolios() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'portfolios')))
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_PORTFOLIOS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_PORTFOLIOS_FAILURE });
        return error;
    }
}

export function* getPropertyDetails(params) {
    try {
        const response = yield call(() => getDoc(doc(fbFirestore, 'ad', `${params.propertyId}`)))
        const data = response.data()
        console.log(data)
        yield put({
            type: actions.GET_PROPERTY_DETAILS_SUCCESS,
            payload: data,
            propertyId: params.propertyId
        });
        // const user = {currentUser: params.profileId}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        // const user = {currentUser: 'BT2100003'}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
        yield put({ type: actions.GET_PROPERTY_DETAILS_FAILURE });
        return error;
    }
}

// export function* getFilteredProperties(params) {
//     try {
//         console.log(params.filter.type, params.filter.value)
//         const response = yield call(() => getDocs(collection(fbFirestore, 'ad')))
//         const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
//         console.log(data)
//         yield put({
//             type: actions.GET_PORTFOLIOS_SUCCESS,
//             payload: data,
//         });
//     } catch (error) {
//         console.log(error)
//         yield put({ type: actions.GET_PORTFOLIOS_FAILURE });
//         return error;
//     }
// }

export default function* clutchSaga() {
    yield all([
        takeEvery(actions.CREATE_PORTFOLIOS_START, createPortfolio),
        takeEvery(actions.GET_PORTFOLIOS_START, getPortfolios),
    ]);
}
