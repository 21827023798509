import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import scriptsReducer from './main/scripts/reducer';
import clutchReducer from './main/clutch/reducer';
import subscribeReducer from './main/subscribe/reducer';
import notificationsReducer from './main/notifications/reducer';
import pursuitReducer from './main/pursuit/reducer';


export const rootReducer = combineReducers({
    authReducer,
    subscribeReducer,
    scriptsReducer,
    clutchReducer,
    pursuitReducer,
    notificationsReducer,
});
