import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Grid, Link, Paper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import { Toaster } from 'react-hot-toast';
import DashLayout from '../../Shared/DashLayout';


const Insurance = () => {
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        // dispatch({type: actions.GET_PROPERTIES_START});
    }
    const loadProperty = (value) => {
        // dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const classes = customStyles();
    return(
        <>
            <Toaster />
            <DashLayout component={
                <div>
                    <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                        <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                        <Link className={classes.textDecoration}> First-Line </Link>
                    </Breadcrumbs>
                    <Typography variant="h5" style={{color: "#9933ff", padding: 10, fontWeight: 600}}>First-Line</Typography>
                    <div style={{margin: 20, marginTop: 10}}>
                        <Grid item xs={12} sm={6} md={4} lg={4} key={1}>
                            <Paper style={{padding: '1rem', borderRadius: 20, border: '1px solid rgb(234, 231, 239)', boxShadow: 'none'}}>         
                                <Typography variant="h5" style={{fontSize: '20px', fontWeight: 600}}>Insurance</Typography>
                                <Typography variant="h6" style={{fontSize: '16px', fontWeight: 500}}>Insurance is a financial agreement between an insurance company and an individual or business that protects against financial loss from unexpected events. The insured pays a regular fee, known as a premium, to the insurer in exchange for coverage.</Typography>
                            </Paper>
                        </Grid>
                    </div>
                </div>
            } />
        </>
    );
}

export default Insurance;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',
    
        '&:hover':{
            color: '#182230',
        }
    },
})
