import React from 'react';
import { AppProvider, Navigation } from '@toolpad/core/AppProvider';
import { DashboardLayout, ThemeSwitcher, ToolbarActions } from '@toolpad/core/DashboardLayout';
import {useDispatch} from 'react-redux';
import {Button, Stack, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PasswordIcon from '@mui/icons-material/Password';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import actions from '../../redux/auth/actions';


const menus = [
    {
      segment: 'user/dashboard',
      title: 'Dashboard',
      icon: <DashboardIcon />,
    },
    {
      kind: 'header',
      title: 'Products',
    },
    {
      segment: 'user/clutch',
      title: 'Clutch',
      icon: <PriceChangeOutlinedIcon />,
    },
    {
      segment: 'user/pursuit',
      title: 'Pursuit',
      icon: <SchoolOutlinedIcon />,
    },
    {
      segment: 'user/firstline',
      title: 'First-Line',
      icon: <CreditScoreIcon />,
    },
    {
      segment: 'user/fortune',
      title: 'Fortune',
      icon: <AssuredWorkloadIcon />,
    },
    {
      segment: 'user/clan',
      title: 'Clan',
      icon: <GroupsOutlinedIcon />,
    },
    {
      kind: 'header',
      title: 'Account Settings',
    },
    {
      segment: 'user/account',
      title: 'Account',
      icon: <PersonOutlineIcon />,
    },
    {
      segment: 'user/account/changepassword',
      title: 'Change Password',
      icon: <PasswordIcon />,
    },
    {
      segment: 'user/account/subscription',
      title: 'Subscription',
      icon: <SubscriptionsOutlinedIcon />,
    },
    {
      segment: 'user/account/notifications',
      title: 'Notifications',
      icon: <NotificationsNoneOutlinedIcon />,
    },
];

const NavbarActions = () => {
  const dispatch = useDispatch();
  const loadTasks = () => {dispatch({type: actions.LOGOUT_START});}
  return (
    <>
      <ThemeSwitcher />
      <Button onClick={() => loadTasks()}><LogoutIcon /></Button>
    </>
  )
}

const DashLayout = (props) => {
    const {component} = props;
    const classes = customStyles();
    return (
        <AppProvider
            branding={{
                logo:'',
                title: <Typography variant='subtitle' className={classes.title}><span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span></Typography> ,
                homeUrl: '/',
            }}
            navigation={menus}
        >
            <DashboardLayout slots={{toolbarActions: NavbarActions}}>
              {component}
            </DashboardLayout>
        </AppProvider>
    )
}

export default DashLayout;

const customStyles = makeStyles({
    title: {
        flexGrow: 1,
        fontSize: '24px',
        fontWeight: 600,
    }
});