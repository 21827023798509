import { all } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import scriptsSaga from './main/scripts/sagas';
import clutchSaga from './main/clutch/sagas';
import subscribeSaga from './main/subscribe/sagas';
import notificationsSaga from './main/notifications/sagas';
import pursuitSaga from './main/pursuit/sagas';

export default function* rootSaga() {
  yield all([
    authSaga(),
    scriptsSaga(),
    clutchSaga(),
    pursuitSaga(),
    subscribeSaga(),
    notificationsSaga(),
  ]);
}
