import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Tabs, Tab, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router';
import DashLayout from '../../Shared/DashLayout';
import Portfolios from './Portfolios';
import { DisclaimerFooter } from '../../Shared/DisclaimerFooter';


const Clutch = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        // dispatch({type: actions.GET_PROPERTIES_START});
    }
    const loadProperty = (value) => {
        // dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const classes = customStyles();
    return(
        <>
            <DashLayout component={
            <div>
                <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                    <Link href={'/user/dashboard'} className={classes.textDecoration}> Dashboard </Link>
                    <Link className={classes.textDecoration}> Clutch </Link>
                </Breadcrumbs>
                <Typography variant="h5" style={{color: "#339933", padding: 10, fontWeight: 600}}>Clutch</Typography>
                <Portfolios />  

                {/* <div style={{margin: 20, marginTop: 10}}>
                    <TabContext value={value}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable-tabs"
                        >
                            <Tab label="My Watchlist" value={1} />
                            <Tab label="Ace Matrix" value={2} />
                            <Tab label="Free Ace Matrix" value={3} />
                            <Tab label="Premium Ace Matrix" value={4} />
                        </Tabs>
                    
                        <TabPanel value={1}>
                            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>My Watchlist</Typography>
                            <div>
                                <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                            </div>
                        </TabPanel>
                        <TabPanel value={2}>
                            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Ace Matrix</Typography>
                            <div>  
                                <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                            </div>
                        </TabPanel>
                        <TabPanel value={3}>
                            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Free Ace Matrix</Typography>
                            <div>
                                <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                            </div>
                        </TabPanel>
                        <TabPanel value={4}>
                            <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Premium Ace Matrix</Typography>
                            <div>
                                <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                            </div>
                        </TabPanel>
                    </TabContext>
                </div> */}
            <DisclaimerFooter />
            <div style={{padding: 2}}></div>
            </div>
            } />
        </>
    );
}

export default Clutch;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',

        '&:hover':{
            color: '#182230',
        }
    },
})
