import actions from './actions';

const initState = {
    loader: false,
    portfolios: [],
    propertyDetails: [],
};

export default function clutchReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PORTFOLIOS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PORTFOLIOS_SUCCESS: {
            return {
                ...state,
                loader: false,
                portfolios: action.payload,
            };
        }
        case actions.GET_PORTFOLIOS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.CREATE_PORTFOLIOS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.CREATE_PORTFOLIOS_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.CREATE_PORTFOLIOS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        
        default:
            return state;
    }
}
