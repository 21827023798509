import React, {useEffect, useState} from 'react';
import {AppBar, Breadcrumbs, Button, Drawer, Grid, IconButton, TextField, InputLabel, MenuItem, FormControl, Select, Toolbar, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import {Toaster} from 'react-hot-toast';
import actions from '../../redux/auth/actions';
import DashLayout from '../Shared/DashLayout';

const drawerWidth = 400;

const Account = () => {
    const {userDetails} = useSelector(state => state.authReducer);
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState(userDetails[0].name);
    const [contact, setContact] = useState(userDetails[0].contact);
    const [gender, setGender] = useState(userDetails[0].gender);
    const [interestedIn, setInterestedIn] = useState(userDetails[0].interestedIn);
    const [experience, setExperience] = useState(userDetails[0].experience);
    const [qualification, setQualification] = useState(userDetails[0].qualification);
    const [occupation, setOccupation] = useState(userDetails[0].occupation);
    const [age, setAge] = useState(userDetails[0].age);
    const [city, setCity] = useState(userDetails[0].city);
    const [state, setState] = useState(userDetails[0].state);
    const [country, setCountry] = useState(userDetails[0].country);
    const [pincode, setPincode] = useState(userDetails[0].pincode);
    const handleOpen = () =>{
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({ type: actions.GET_USER_DETAILS_START });
    }
    const editProfile = () => {
        dispatch({
            type: actions.EDIT_USER_DETAILS_START,
            payload: {
                authId: userDetails[0].authId,
                email: userDetails[0].email,
                name: name,
                contact: contact,
                gender: gender,
                interestedIn: interestedIn,
                experience: experience,
                qualification: qualification,
                occupation: occupation,
                age: age,
                city: city,
                state: state,
                country: country,
                pincode: pincode,
            }
        });
        setVisible(false);
        dispatch({type: actions.GET_USER_DETAILS_START});
    }
    const classes = customStyles();
    return(
        <>
            <Toaster />
            <DashLayout component={
            <div>
                <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                    <Link href={'/user/dashboard'} className={classes.textDecoration} > Dashboard </Link>
                    <Link className={classes.textDecoration}> Account </Link>
                </Breadcrumbs>
                <Typography variant="h5" style={{color: "#CC9900", paddingLeft: 10, fontWeight: 600}}>Account</Typography>
                <div>
                    <Button variant='outlined' style={{color: '#1842b6', paddingTop: 5, margin: 10, borderRadius: 10, boxShadow: 'none', fontWeight: 600}} onClick={() => handleOpen()}>Edit Profile</Button>
                    <div style={{margin: '0 10px 10px 10px', padding: 10, border: '1px solid rgb(234, 231, 239)', borderRadius: 20}}>
                        <Typography variant='h5' style={{fontSize: '20px', fontWeight: 600, padding: '5px', color: '#1842b6'}}>{userDetails[0].name}</Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Email Address : <span className={classes.primaryTypo}>{userDetails[0].email}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Contact : <span className={classes.primaryTypo}>{userDetails[0].contact}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Interested In : <span className={classes.primaryTypo}>{userDetails[0].interestedIn}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Experience : <span className={classes.primaryTypo}>{userDetails[0].experience}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Qualification : <span className={classes.primaryTypo}>{userDetails[0].qualification}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Occupation : <span className={classes.primaryTypo}>{userDetails[0].occupation}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Gender : <span className={classes.primaryTypo}>{userDetails[0].gender}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Age : <span className={classes.primaryTypo}>{userDetails[0].age}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>City : <span className={classes.primaryTypo}>{userDetails[0].city}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>State :  <span className={classes.primaryTypo}>{userDetails[0].state}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Country : <span className={classes.primaryTypo}>{userDetails[0].country}</span></Typography>
                        <Typography variant='h6' style={{padding: 5, fontSize: '16px'}}>Pincode :  <span className={classes.primaryTypo}>{userDetails[0].pincode}</span></Typography>
                    </div>
                </div>
                <Drawer
                    sx={{
                        position: 'relative',
                        zIndex: '9999',
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    anchor={'right'}
                    open={visible}
                    onClose={handleClose}
                >
                    <AppBar color={'inherit'} position={'static'} style={{boxShadow: 'none', border: '1px solid rgb(234, 231, 239)'}}>
                        <Toolbar disableGutters={false} variant={'regular'}>
                            <Typography color={'#CC9900'} width={350} variant={'h6'}> Edit Profile </Typography>
                            <IconButton edge={'start'} size={'large'} style={{color: '#CC9900'}} onClick={handleClose}><CloseIcon /></IconButton>
                        </Toolbar>
                    </AppBar>
                    <>
                        <TextField
                            placeholder='Name'
                            required
                            variant={'standard'}
                            value={userDetails[0].name}
                            className={clsx(classes.margin20)}
                            style={{marginTop: 40}}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            placeholder='Contact'
                            required
                            variant={'standard'}
                            value={userDetails[0].contact}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setContact(e.target.value)}
                        />
                        <FormControl className={classes.margin20a}>
                            <InputLabel id='gender'>Gender</InputLabel>
                            <Select
                                id='gender'
                                value={gender}
                                label='Gender'
                                onChange={(e) => setGender(e.target.value)}
                            >
                                <MenuItem value={'Male'}>Male</MenuItem>
                                <MenuItem value={'Female'}>Female</MenuItem>
                                <MenuItem value={'Not willing to specify'}>Not willing to specify</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.margin20a}>
                            <InputLabel id='interestedIn'>Interested in</InputLabel>
                            <Select
                                id='interestedIn'
                                value={interestedIn}
                                label='Interested in'
                                onChange={(e) => setInterestedIn(e.target.value)}
                            >
                                <MenuItem value={'Short Term Trading'}>Short Term Trading</MenuItem>
                                <MenuItem value={'Long Term Trading'}>Long Term Trading</MenuItem>
                                <MenuItem value={'Investing'}>Investing</MenuItem>
                                <MenuItem value={'All the above'}>All the above</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.margin20a}>
                            <InputLabel id='experience'>Experience</InputLabel>
                            <Select
                                id='experience'
                                value={experience}
                                label='Experience'
                                onChange={(e) => setExperience(e.target.value)}
                            >
                                <MenuItem value={'Less than 1 year'}>Less than 1 year</MenuItem>
                                <MenuItem value={'Less than 3 year'}>Less than 3 year</MenuItem>
                                <MenuItem value={'Above 3 year'}>Above 3 year</MenuItem>
                                <MenuItem value={'Above 5 year'}>Above 5 year</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            placeholder='Qualification'
                            required
                            variant={'standard'}
                            value={userDetails[0].qualification}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setQualification(e.target.value)}
                        />
                        <TextField
                            placeholder='Occupation'
                            required
                            variant={'standard'}
                            value={userDetails[0].occupation}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setOccupation(e.target.value)}
                        />
                        <TextField
                            placeholder='Age'
                            required
                            variant={'standard'}
                            value={userDetails[0].age}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setAge(e.target.value)}
                        />
                        <TextField
                            placeholder='City'
                            required
                            variant={'standard'}
                            value={userDetails[0].city}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <TextField
                            placeholder='State'
                            required
                            variant={'standard'}
                            value={userDetails[0].state}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setState(e.target.value)}
                        />
                        <TextField
                            placeholder='Country'
                            required
                            variant={'standard'}
                            value={userDetails[0].country}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                        <TextField
                            placeholder='Pincode'
                            required
                            variant={'standard'}
                            value={userDetails[0].pincode}
                            className={clsx(classes.margin20)}
                            onChange={(e) => setPincode(e.target.value)}
                        />
                        <Button
                            key='yes'
                            type='submit'
                            color='primary'
                            variant='contained'
                            className={classes.modalBtn}
                            onClick={() => editProfile()}
                        >
                            Save Profile
                        </Button>
                    </>
                </Drawer>
            </div>
            } />
        </>
    )
}

export default Account;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',

        '&:hover':{
            color: '#182230',
        }
    },
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
    primaryTypo: {
        fontWeight: 600,
    },
    margin20a: {
        margin: 20
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: 10,
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFF',
        backgroundColor: '#00B050',
        fontWeight: 600,

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#EBF5F0',
            color: '#00B050',
        },
    },
})
