import {all, call, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';

const auth = JSON.parse(window.sessionStorage.getItem('auth'));


export function* getScripts() {
    try {
        const response = yield call(axios.get, 'https://script.google.com/macros/s/AKfycbyTHrSjQx9fM9uI2PdcfYkFnpPDROn8giUrsr9biYl6-Z-dmJnx4klDW0wG_HoWWGkV/exec', {mode: 'cors'});
        console.log(response)
        yield put({
            type: actions.GET_SCRIPTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_SCRIPTS_FAILURE });
        return error;
    }
}

export default function* scriptsSaga() {
    yield all([
        takeEvery(actions.GET_SCRIPTS_START, getScripts),
    ]);
}
