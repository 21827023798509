import actions from './actions';

const initState = {
    loader: false,
    subscriptionDetails: []
};

export default function subscribeReducer(state = initState, action) {
    switch (action.type) {
        case actions.CREATE_SUBSCRIPTION_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.CREATE_SUBSCRIPTION_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                subscriptionDetails: action.payload,
            };
        }
        case actions.CREATE_SUBSCRIPTION_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
