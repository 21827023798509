import {all, call, put, takeEvery} from 'redux-saga/effects';
import {query, getDocs, collection, where} from 'firebase/firestore';
import {fbFirestore} from '../../../helpers/firebaseClient';
import actions from './actions';


export function* getCourses() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'courses')))
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_COURSES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_COURSES_FAILURE });
        return error;
    }
}

export function* getCourseDetails(params) {
    console.log(params.id)
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'courses'),where('id', '==', `${params.id}`))));
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_COURSE_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({ type: actions.GET_COURSE_DETAILS_FAILURE });
        console.log(error)
        return error;
    }
}

export default function* pursuitSaga() {
    yield all([
        takeEvery(actions.GET_COURSES_START, getCourses),
        takeEvery(actions.GET_COURSE_DETAILS_START, getCourseDetails),
    ]);
}
