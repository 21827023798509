import React from 'react'
import {Button, Grid, Paper, TextField, Typography, Tabs, Tab} from '@mui/material';
import {makeStyles} from '@mui/styles';
import Navbar from '../../Shared/Navbar';
import Footer from '../../Shared/Footer';

const PartnerProgram = () => {
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 30, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h5' style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>Become a Partner</Typography>
                <Typography variant='h6' style={{fontSize: '15px', textAlign: 'center', color: '#182230'}}>We make stock market simple and inspiring</Typography>
                <Typography variant='h6' style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#FFBF00'}}>Learn</span> . <span style={{color: '#0070C0'}}>Analyse</span> . <span style={{color: '#00B050'}}>Execute</span> . <span style={{color: '#FF5733'}}>Make Fortune</span></Typography>
            </div>
            <div style={{padding: 10}}>                
                <Typography variant='h5' style={{color: '#0c5aa5', textAlign: 'center', fontWeight: 600}}>Franchisee Business Partnership Program</Typography>
                <Typography variant='h6' style={{color: '#182230', textAlign: 'center', fontWeight: 500}}>Become part of the Ace Matrix and grow your business with us</Typography>
            </div>
            <div style={{margin: 20, marginTop: 10}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper className={classes.cardSize}>
                            <Typography variant='h6' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>Franchise Cost & Commissions</Typography>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Franchise Cost</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Courses</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>App Subscription</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Live Webinar</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#7F90A2', fontWeight: 500, margin: 10, marginLeft: 0}}>Workshops</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>₹75,000</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>30% - 50%</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>40%</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>30% - 50%</Typography>
                                    <Typography variant='h6' style={{fontSize: '16px', color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>30% - 50%</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper className={classes.cardSize}>
                            <Typography variant='h6' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>Subscription Enquiry</Typography>
                            <TextField
                                label='Name'
                                placeholder='Enter your name'
                                fullWidth
                                required
                                className={classes.margin10}
                                // onChange={(e) => setTransactionId(e.target.value)}
                            />
                            <TextField
                                label='Email Address'
                                placeholder='Enter your email address'
                                fullWidth
                                required
                                className={classes.margin10}
                                // onChange={(e) => setTransactionId(e.target.value)}
                            />
                            <TextField
                                label='Contact Number'
                                placeholder='Enter your contact number'
                                fullWidth
                                required
                                className={classes.margin10}
                                // onChange={(e) => setTransactionId(e.target.value)}
                            />
                            <TextField
                                label='Age'
                                placeholder='Enter your age'
                                fullWidth
                                required
                                className={classes.margin10}
                                // onChange={(e) => setHolderName(e.target.value)}
                            />
                            <TextField
                                label='Education'
                                placeholder='Enter your educational qualification'
                                fullWidth
                                required
                                className={classes.margin10}
                                // onChange={(e) => setBankName(e.target.value)}
                            />
                            <Button
                                type='submit'
                                color='primary'
                                variant='contained'
                                fullWidth
                                className={classes.postBtn}
                                // onClick={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}></Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}

export default PartnerProgram;

const customStyles = makeStyles({
    cardSize: {
        padding: 30,
        margin: 'auto',
        borderRadius: 40,
        border: '1px solid rgb(234, 231, 239)',
        boxShadow: 'none',
    },
    postBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '10px 0px',
        boxShadow: 'none',
        borderRadius: 20,
        color: '#FFF',
        backgroundColor: '#00B050',
        fontWeight: 600,

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#EBF5F0',
            color: '#00B050',
        },
    },
    margin10: {
        margin: '10px 0'
    }
})
