import React, {useState} from 'react';
import {Link} from 'react-router';
import {AppBar, Button, Drawer, IconButton, Hidden, List, ListItem, ListItemText, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {menus} from '../../helpers/constant';


const sessionStorage = JSON.parse(window.sessionStorage.getItem('auth'));
const auth = sessionStorage === null ? {isUserLoggedIn: false} : sessionStorage;

const Navbar = () => {
    const classes = customStyles();
    const [visible, setVisible] = useState(false);

    const toggleDrawer = () => {
        setVisible(false)
    };
    const handleMobileDrawerOpen = () => {
        setVisible(true)
    };

    return (
        <div className={classes.root}>
            <AppBar position='fixed' style={{borderBottom: '1px solid rgb(234, 231, 239)'}}>
                <Toolbar>
                    <Typography variant='h5' style={{flexGrow: 1, fontWeight: 600, color: '#1A56DB'}}> ACE MATRIX </Typography> 
                    <Hidden smUp>
                        <IconButton
                            className={classes.menuButton}
                            onClick={handleMobileDrawerOpen}
                            aria-label='Open Navigation'
                        >
                            <MenuIcon color='primary' />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        {menus.map(element => {
                            if (element.link) {
                                return (
                                    <Link
                                        key={element.name}
                                        to={element.link}
                                        className={clsx(classes.textDecoration, classes.fw600)}
                                    >
                                        <Button
                                            disableRipple
                                            variant={element.variant}
                                            className={element.button ? classes.postBtn : classes.navLink}
                                        >
                                            {element.button && auth.isUserLoggedIn ? 'Dashboard' : element.name}
                                        </Button>
                                    </Link>
                                );
                            }
                            return (
                                <Button
                                    color='secondary'
                                    size='large'
                                    onClick={element.onClick}
                                    key={element.name}
                                >
                                    {element.name}
                                </Button>
                            );
                        })}
                    </Hidden>
                </Toolbar>
            </AppBar>
            <Drawer open={visible} onClose={toggleDrawer}>
                <div
                    style={{width: 250}}
                    role='presentation'
                    onClick={toggleDrawer}
                    onKeyDown={toggleDrawer}
                >
                    <Typography variant='h5' style={{padding: '10px 16px 10px 16px'}}>
                        <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                    </Typography> 
                    <hr style={{margin: 0}} />
                    <List>
                        {menus.map((value) => (
                            <ListItem button key={value.name}>
                                <Link to={value.link} className={clsx(classes.textDecoration)} style={{color: 'rgb(32, 34, 52)'}}><ListItemText primary={value.name} /></Link>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;

const customStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    fw600: {
        fontWeight: 600,
    },
    title: {
        flexGrow: 1,
        fontWeight: 600
    },
    menuButton: {
        borderRadius: 10,
        backgroundColor: '#f7f7f7',
        color: '#808080',
        fontSize: 10,

        '&:hover': {
            color: '#000',
            backgroundColor: '#f7f7f7',
        },
    },
    margin8a: {
        margin: '10px',
    },
    navLink: {
        color: '#182230',
        margin: 0,
        fontSize: '16px',
        fontWeight: 500,
        textDecoration: 'none',
        textTransform: 'capitalize',

        '&:hover': {
            color: '#1A56DB',
            backgroundColor: 'inherit',
        }
    },
    textDecoration: {
        textDecoration: 'none'
    },
    postBtn: {
        minWidth: 100,
        margin: '5px',
        padding: '5px 20px',
        boxShadow: 'none',
        borderRadius: 12,
        color: '#FFF',
        backgroundColor: '#1A56DB',

        '&:hover': {
            color: '#1A56DB',
            boxShadow: 'none',
            backgroundColor: '#E8EFFC',
        },
    },
});
