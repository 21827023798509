import React, {useEffect, useState} from 'react';
import {Link} from 'react-router';
import {Button, Grid, Paper, Typography, Tabs, Tab} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';
import {products} from '../../helpers/constant';

const Products = () => {
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        setValue(value);
    };
    const classes = customStyles();
    return(
        <>
            {/* <AuthNavbar value={'Scripts'} /> */}
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 30, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant='h5' style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>Products for our members</Typography>
                <Typography variant='h6' style={{fontSize: '15px', textAlign: 'center', color: '#182230'}}>Join us and regulate your savings & investments</Typography>
                <Typography variant='h6' style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#FFBF00'}}>Learn</span> . <span style={{color: '#0070C0'}}>Analyse</span> . <span style={{color: '#00B050'}}>Execute</span> . <span style={{color: '#FF5733'}}>Make Fortune</span></Typography>
            </div>
            <div style={{marginTop: 10}}>
                <Typography variant='h5' style={{color: '#0c5aa5', padding: '1rem', textAlign: 'center', fontWeight: 600}}>Ace Matrix Products</Typography>
                <Grid container>
                    {products.map((data) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={data.companyName}>
                            <Paper style={{margin: '1.5rem', padding: '1rem', boxShadow: 'none', borderRadius: 20, backgroundColor: `${data.bgColor}`}}>
                                <Typography variant='h6' style={{fontWeight: 600, fontSize: '18px'}}>
                                    {data.title}
                                </Typography>
                                <Typography variant='h6' style={{fontWeight: 600, color: '#7F90A2', fontSize: '14px'}}>
                                    {data.slug}
                                </Typography>
                                <Grid container >
                                    <Grid item xs={12} md={12} lg={12} style={{padding: 5}}>
                                        <Typography variant='h6' className={classes.padding8} style={{fontSize: '16px'}}>
                                            {data.shortDesc}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} style={{padding: 5}}>
                                        <Link to={`${data.link}`} className={clsx(classes.textDecoration)}>
                                            <Button variant='outlined' style={{fontSize: '12px', fontWeight: 600}}>
                                                {data.btnTitle}
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <Footer />
        </>
    )
}

export default Products;

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: '40px',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
