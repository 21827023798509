import {all, call, put, takeEvery} from 'redux-saga/effects';
import {doc, setDoc} from 'firebase/firestore';
import {fbFirestore} from '../../../helpers/firebaseClient';
import actions from './actions';


export function* createSubscription(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'subscriptionDetails', `${'PAY' + randNum}`), params.planDetails));
        yield put({
            type: actions.CREATE_SUBSCRIPTION_DETAILS_SUCCESS,
        });
    } catch (error) {
        yield put({ type: actions.CREATE_SUBSCRIPTION_DETAILS_FAILURE });
        console.log(error)
        return error;
    }
}

export default function* subscribeSaga() {
    yield all([
        takeEvery(actions.CREATE_SUBSCRIPTION_DETAILS_START, createSubscription),
    ]);
}
