import React from 'react'
import {Grid, Paper, Typography, Tabs, Tab} from '@mui/material';
import {makeStyles} from "@mui/styles";
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';

const About = () => {
    const classes = customStyles();
    return(
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{padding: 15, margin: 15, borderRadius: 30, border: '1px solid rgb(234, 231, 239)'}}>
                <Typography variant="h5" style={{fontSize: '28px', textAlign: 'center', color: '#182230'}}>About ACE MATRIX</Typography>
                <Typography variant="h6" style={{fontSize: '15px', textAlign: 'center', color: '#182230'}}>We make stock market simple and inspiring</Typography>
                <Typography variant="h6" style={{fontSize: '16px', textAlign: 'center', fontWeight: 600}}><span style={{color: '#FFBF00'}}>Learn</span> . <span style={{color: '#0070C0'}}>Analyse</span> . <span style={{color: '#00B050'}}>Execute</span> . <span style={{color: '#FF5733'}}>Make Fortune</span></Typography>
            </div>
            <Grid style={{padding: 16}}>                
                <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>About Us</Typography>
                <Typography variant="h6" style={{color: "#182230", padding: "1rem", textAlign: "center", fontWeight: 500}}>We want India's Future Pillars to travel in passionate and organised way to empower their standard of living and adopt to unpredictable economic changes happening in India. Not only abroad employement will make you rich but also the plan that every millionaires and billionaires followed to get successful with the opportunities in India. Our Membership will be your guide to reach such goals.</Typography>
            </Grid>
            <Footer />
        </>
    )
}

export default About;

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: "40px",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
