import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumbs, Card, CardMedia, CardContent, CardActions, Divider, Grid, Link, Tab, Tabs, Typography, Button} from '@mui/material';
import {ExpandLess, ExpandMore, SubscriptionsOutlined} from '@mui/icons-material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import {makeStyles} from '@mui/styles';
import DashLayout from '../../Shared/DashLayout';
import Courses from './Courses';
import actions from '../../../redux/main/pursuit/actions';


const Pursuit = () => {
    const {courses} = useSelector(state => state.pursuitReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_COURSES_START});
    };
    const classes = customStyles();
    console.log(courses);
    return(
        <>
            <DashLayout component={
            <div>
                <Breadcrumbs style={{margin: 10, color: '#868686', fontSize: '16px'}}>
                    <Link href={'/user/dashboard'} className={classes.textDecoration}> Dashboard </Link>
                    <Link className={classes.textDecoration}> Pursuit </Link>
                </Breadcrumbs>
                <Typography variant="h5" style={{color: "#ff3300", padding: 10, fontWeight: 600}}>Pursuit</Typography>
                <div style={{margin: 20, marginTop: 10}}>
                    <Courses courses={courses} />
                </div>
            </div>
            } />
        </>
    );
}

export default Pursuit;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: "none",
        color: '#7F9082',

        '&:hover':{
            color: '#182230',
        }
    },
})
