const actions = {
    GET_PORTFOLIOS_START: 'GET_PORTFOLIOS_START',
    GET_PORTFOLIOS_SUCCESS: 'GET_PORTFOLIOS_SUCCESS',
    GET_PORTFOLIOS_FAILURE: 'GET_PORTFOLIOS_FAILURE',
    CREATE_PORTFOLIOS_START: 'CREATE_PORTFOLIOS_START',
    CREATE_PORTFOLIOS_SUCCESS: 'CREATE_PORTFOLIOS_SUCCESS',
    CREATE_PORTFOLIOS_FAILURE: 'CREATE_PORTFOLIOS_FAILURE',
};

export default actions;
