import {all, call, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import {history} from '../store';

import {fbFirestore, firebaseInit} from '../../helpers/firebaseClient';

import {createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut} from 'firebase/auth';
import {query, where, getDocs, collection, doc, setDoc} from 'firebase/firestore';

import {toast} from 'react-hot-toast';

const authInit = getAuth();
const auth = JSON.parse(window.sessionStorage.getItem('auth'));

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');


export function* userRegistration(params) {
    const name = params.payload.name
    const email = params.payload.email
    const password = params.payload.password
    const contact = params.payload.contact
    try {
        const registerAuth = yield call(() => createUserWithEmailAndPassword(authInit, email, password));
        const registerDetails = {
            name: name,
            email: email,
            contact: contact,
            authId: registerAuth.user.uid
        }
        const response = yield call(() => setDoc(doc(fbFirestore, 'users', `${registerAuth.user.uid}`), registerDetails));
        yield put({
            type: actions.USER_REGISTRATION_SUCCESS,
            payload: response,
        });
        toast.success('Registration Successful');
    } catch (error) {
        yield put({ type: actions.USER_REGISTRATION_FAILURE });
        return toast.error(error.message);
    }
}

export function* googleUserRegistration() {
    try {
        const register = yield call(() => signInWithPopup(authInit, provider));
        const registerDetails = {
            name: register.user.displayName,
            email: register.user.email,
            authId: register.user.uid
        }
        const response = yield call(() => setDoc(doc(fbFirestore, 'users', `${register.user.uid}`), registerDetails));
        yield put({
            type: actions.USER_REGISTRATION_GOOGLE_SUCCESS,
            payload: response,
        });
        toast.success('Registration Successful');
    } catch (error) {
        yield put({ type: actions.USER_REGISTRATION_GOOGLE_FAILURE });
        return toast.error(error.message);
    }
}

export function* login(params) {
    const email = params.loginData.email
    const password = params.loginData.password
    try {
        const response = yield call(() => signInWithEmailAndPassword(authInit, email, password));
        const authDetails = yield call (() => getDocs(query(collection(fbFirestore, 'users'),where('authId', '==', `${response.user.uid}`))))
        toast.success(`Welcome ${authDetails.docs.map((doc) => ({...doc.data()}))[0].name}`)
        yield put({
            type: actions.LOGIN_SUCCESS,
            payload: response.user,
            authDetails: authDetails.docs.map((doc) => ({...doc.data(), userId: doc.id})),
        });
        const auth = {
            token: response.user.uid,
            isUserLoggedIn: true,
        }
        history.push('/user/dashboard');
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        window.location.reload(false);
    } catch (error) {
        yield put({ type: actions.LOGIN_FAILURE });
        const auth = {
            token: '',
            isUserLoggedIn: false,
        }
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        return toast.error(error.message);
    }
}

export function* googleLogin() {
    try {
        const response = yield call(() => signInWithPopup(authInit, provider));
        const authDetails = yield call (() => getDocs(query(collection(fbFirestore, 'users'),where('authId', '==', `${response.user.uid}`))))
        console.log(response)
        toast.success(`Welcome ${response.user.name}`)
        yield put({
            type: actions.GOOGLE_LOGIN_SUCCESS,
            payload: response.user,
            authDetails: authDetails.docs.map((doc) => ({...doc.data(), userId: doc.id})),
        });
        const auth = {
            token: response.user.uid,
            isUserLoggedIn: true,
        }
        history.push('/user/dashboard');
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        window.location.reload(false);
    } catch (error) {
        yield put({ type: actions.GOOGLE_LOGIN_FAILURE });
        const auth = {
            token: '',
            isUserLoggedIn: false,
        }
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        return toast.error(error.message);
    }
}

export function* logout() {
    try {
        const response = yield call(() => signOut(authInit));
        window.sessionStorage.removeItem('auth');
        history.push('/')
        window.location.reload(false);
        yield put({
            type: actions.LOGOUT_SUCCESS,
            payload: response,
        });
    } catch (error) {
        yield put({ type: actions.LOGOUT_FAILURE });
        return toast.error(error.message);
    }
}

export function* userDetails(params) {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'users'),where('authId', '==', `${auth.token}`))))
        yield put({
            type: actions.GET_USER_DETAILS_SUCCESS,
            payload: response.docs.map((doc) => ({...doc.data(), userId: doc.id}))
        });
    } catch (error) {
        yield put({ type: actions.LOGIN_FAILURE });
        return toast.error(error);
    }
}

export function* editUserDetails(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'users', params.payload.authId), params.payload))
        yield put({ type: actions.EDIT_USER_DETAILS_SUCCESS });
    } catch (error) {
        yield put({ type: actions.EDIT_USER_DETAILS_FAILURE });
        return toast.error(error.message);
    }
}


export default function* authSaga() {
    yield all([
        takeEvery(actions.USER_REGISTRATION_START, userRegistration),
        takeEvery(actions.USER_REGISTRATION_GOOGLE_START, googleUserRegistration),
        takeEvery(actions.LOGIN_START, login),
        takeEvery(actions.GOOGLE_LOGIN_START, googleLogin),
        takeEvery(actions.LOGOUT_START, logout),
        takeEvery(actions.GET_USER_DETAILS_START, userDetails),
        takeEvery(actions.EDIT_USER_DETAILS_START, editUserDetails),
    ]);
}
