import actions from './actions';

const initState = {
    loader: false,
    indices: [],
    scripts: [],
    scriptssData: [],
    offDocs: []
};

export default function scriptsReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_INDICES_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_INDICES_SUCCESS: {
            return {
                ...state,
                loader: false,
                indices: action.payload,
            };
        }
        case actions.GET_INDICES_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_SCRIPTS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_SCRIPTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                scripts: action.payload.futureData,
                futures: action.payload.futureData,
                indices: action.payload.indicesData,
                nifty: action.payload.niftyData,
                niftyNext: action.payload.niftyNextData,
                niftyMidcap: action.payload.niftyMidcapData,
            };
        }
        case actions.GET_SCRIPTS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
